import { createSlice } from "@reduxjs/toolkit";
const LoggedUserDetailsSlice = createSlice({
  name: "loggedUser",
  initialState: {
    loggedUserData: null,
  },
  reducers: {
    addLoggedUserDetails: (state, { payload }) => {
      // console.log("payload>>>>>>", payload);
      state.loggedUserData = payload;
    },
  },
});
export const { addLoggedUserDetails } = LoggedUserDetailsSlice.actions;
export default LoggedUserDetailsSlice.reducer;
