import React, { useEffect, useState } from "react";
import "./FinetuneBody.css";
import UpdateWeightsModal from "../subcomponents/finetuneSubcomponents/UpdateWeightsModal";
import StartTrainingModal from "../subcomponents/finetuneSubcomponents/StartTrainingModal";
import ProgressFinetuningModal from "../subcomponents/finetuneSubcomponents/ProgressFinetuningModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBaseProjects,
  getProjectDetail,
  getProjectVersion,
} from "../../Store/AllProjectsSlice";
import MergeTraceModal from "../subcomponents/finetuneSubcomponents/MergeTraceModal";
import { getTraces } from "../../Store/TracesSlice";
import {
  FINETUNE_STATUS,
  GET_FINETUNE_STATUS,
  GET_PROJECTDETAILS,
  GET_TRACES,
  RUN_FINETUNE_RLHF,
} from "../../util/ApiEndPoints";
import axios from "axios";
import _ from "lodash";
const FinetuneBody = ({ activeApp, traceData, setTracesData }) => {
  const { projectDetailData, projectVersions, baseProjectsList } = useSelector(
    (state) => state?.projectsList
  );
  // const { projectDetailData } = useSelector(
  //   (state) => state?.projectsList
  // );
  const savedUserDetails = localStorage.getItem("userDetails");
  const userDetails = JSON.parse(savedUserDetails);
  const customHeaders = {
    Authorization: `Bearer ${userDetails?.access_token}`,
  };
  const [updateWeight, setUpdateWeight] = useState(false);
  const [showUpdateWeightsModal, setShowUpdateWeightsModal] = useState(false);
  const [mergeExistingTrace, setMergeExistingTrace] = useState(false);
  const [startTraining, setStartTraining] = useState(false);
  const [showProjectStatusProgress, setshowProjectStatusProgress] =
    useState(false);
  const [showProgressFinetuningModal, setShowProgressFinetuningModal] =
    useState(false);
  const [showProjectStatusCompleted, setShowProjectStatusCompleted] =
    useState(false);
  const [updatedUsersList, setUpdatedUsersList] = useState([]);
  const [showProjectStatus, setShowProjectStatus] = useState(false);
  const [status, setStatus] = useState("");
  const [activeProject, setActiveProject] = useState(null);
  const [renderProjectsList, setRenderProjectsList] = useState([]);
  const [showMergeTraceModal, setShowMergeTraceModal] = useState(false);
  const [projectVersionsdata, setProjectVersionsdata] = useState([]);
  const [collectedMergeTraces, setCollectedMergeTraces] = useState([]);
  const [projectVersionsList, setProjectVersionsList] = useState();
  const [pendingProjectsList, setPendingProjectsList] = useState([]);
  const [inprogressProjectsList, setInprogressProjectsList] = useState([]);
  const [completedProjectsList, setCompletedProjectsList] = useState([]);
  const [activeProjectVersion, setActiveProjectVersion] = useState(null);
  const [selectedProjectVersion, setSelectedProjectVersion] = useState(null);
  const [renderProjectVersions, setRenderProjectVersions] = useState([]);
  const [isUpdateWeight, setIsUpdateWeight] = useState("");
  const [ismergeTrace, setIsMergeTrace] = useState("");

  // const [finetuneProcessID, setFinetuneProcessID] = useState(null);
  // const [showFinetuneStarted, setShowFinetuneStarted] = useState(false);
  // const [updatedInprogressProjectsList, setUpdatedInprogressProjectsList] =
  //   useState([]);
  const [projectFinetuneStatus, setProjectFinetuneStatus] = useState(null);
  // const [versionStatus, setVersionStatus] = useState(null);
  // const [projectVersion, setProjectVersion] = useState(null);
  const dispatch = useDispatch();

  // console.log("activeapppp", activeApp);
  // console.log("baseProejctsList", baseProejctsList);
  // console.log("projectsList", renderProjectsList);
  // console.log("projectVersions", projectVersions);
  // console.log("projectDetailData", projectDetailData);
  // console.log("updatedUsersList", updatedUsersList);
  // console.log("traceData", traceData);
  // console.log("Projectversion", projectVersion);
  // console.log("Projectversionslist", projectVersionsList);
  // console.log("collectedMergeTraces", collectedMergeTraces);
  // console.log("inProgressProjects", inProgressProjects);
  // console.log("projectVersionsdata", projectVersionsdata);
  // console.log("pendingProjectsList", pendingProjectsList);
  // console.log("activeProjectVersion", activeProjectVersion);
  // console.log("inprogressProjectsList", inprogressProjectsList);
  // console.log("projectFinetuneStatus", projectFinetuneStatus);
  // console.log("renderProjectVersions", renderProjectVersions);
  useEffect(() => {
    // console.log("orgid|}|}", userDetails.orgId);
    dispatch(getTraces(GET_TRACES + userDetails?.orgId));
  }, [dispatch, userDetails?.orgId]);
  useEffect(() => {
    if (activeApp) {
      dispatch(
        getAllBaseProjects({
          orgId: userDetails?.orgId,
          appId: activeApp?.app_id,
        })
      );
    }
  }, [activeApp, activeApp?.app_id, dispatch, userDetails?.orgId]);
  useEffect(() => {
    if (baseProjectsList) {
      const newProjectsList = baseProjectsList?.map((project, index) => {
        if (index === 0) {
          return {
            ...project,
            isSelected: true,
          };
        } else {
          return {
            ...project,
            isSelected: false,
          };
        }
      });
      setRenderProjectsList(newProjectsList);
      setActiveProject(baseProjectsList[0]);
    }
  }, [baseProjectsList, dispatch]);
  useEffect(() => {
    if (activeProject) {
      dispatch(
        getProjectDetail({
          projectId: activeProject?.projectId,
          orgId: userDetails?.orgId,
        })
      );
      dispatch(getProjectVersion({ projectId: activeProject?.projectId }));
    }
  }, [activeProject, dispatch, userDetails?.orgId]);
  useEffect(() => {
    if (projectDetailData) {
      setUpdatedUsersList(projectDetailData?.users);
      let Versions = [];
      _?.map(projectDetailData?.otherVersions, (vr) => {
        Versions.push(String(vr.projectId));
      });
      setProjectVersionsList(Versions);
    }
  }, [projectDetailData]);
  // useEffect(() => {
  //   if (inprogressProjectsList) {
  //     const newProgressProjectslist = _.map(
  //       inprogressProjectsList,
  //       (project) => {}
  //     );
  //   }
  // }, []);
  useEffect(() => {
    if (projectVersions) {
      setRenderProjectVersions([
        ...projectVersions,
        {
          version: "Default",
        },
      ]);
    }
  }, [projectVersions]);
  const handleupdateWeight = () => {
    if (!selectedProjectVersion) {
      alert("Please select  version first");
    } else {
      setShowUpdateWeightsModal(true);
      // setUpdateWeight(true);
    }
  };
  const handleclickProject = (project) => {
    dispatch(getProjectVersion({ projectId: project?.projectId }));
    dispatch(
      getProjectDetail({
        projectId: project?.projectId,
      })
    );
    const newProjectsList = baseProjectsList?.map((item) => {
      if (item.projectId === project?.projectId) {
        return {
          ...item,
          isSelected: true,
        };
      } else {
        return {
          ...item,
          isSelected: false,
        };
      }
    });
    setRenderProjectsList(newProjectsList);
    setActiveProject(project);
    setStatus("");
    setInprogressProjectsList([]);
    setCompletedProjectsList([]);
    setPendingProjectsList([]);
    setSelectedProjectVersion(false);
  };
  const handleProjectVersion = async (value) => {
    // setProjectVersion(value);
    setSelectedProjectVersion(value);
    // console.log("version??", value);
    let vr;
    if (value === "Default") {
      vr = activeProject;
    } else {
      vr = projectVersions?.find((ver) => {
        return ver?.version === value;
      });
    }

    // console.log("vrrrrrrrrr", vr);
    const res = await axios.post(
      GET_PROJECTDETAILS,
      {
        projectId: vr?.projectId,
        orgId: userDetails?.orgId,
      },
      {
        headers: customHeaders,
      }
    );
    // console.log("ressssssssssssssssssssssssssssssss", res.data);
    setProjectVersionsdata(res?.data);
  };
  // console.log("activeProject", activeProject);

  const handleProjectStatus = async (value, status) => {
    setStatus(value);
    try {
      const res = await axios.post(GET_FINETUNE_STATUS, {
        projectList: projectVersionsList,
        status: status,
      });

      if (res.status === 200) {
        // console.log("statusss", res.data);
        if (status === 0) {
          setPendingProjectsList(res.data.data);
        }
        if (status === 1) {
          // console.log("statusss", res.data);
          // setInprogressProjectsList(res.data.data);
          const newProgressPrjects = res.data.data.map((project) => {
            return {
              ...project,
              isSelected: false,
            };
          });
          // console.log("progress", newProgressPrjects);
          setInprogressProjectsList(newProgressPrjects);
        }
        if (status === 2) {
          setCompletedProjectsList(res.data.data);
        }
      }
    } catch (error) {
      console.log("Error", error);
    }
  };
  const handleProjectVersionShowStatus = async (value, status) => {
    setStatus(value);
    const res = await axios.post(GET_FINETUNE_STATUS, {
      projectList: [activeProjectVersion?.projectId],
      status: status,
    });
    if (res.status === 200) {
      // console.log("ressssssssssssss", res.data);
      alert(JSON.stringify(res.data));
    }
  };

  const handleRunFinetune = async () => {
    try {
      const res = await axios.post(RUN_FINETUNE_RLHF, {
        orgId: userDetails?.orgId,
        projectId: activeProjectVersion?.projectId,
        appId: activeApp?.app_id,
        appVersion: activeProjectVersion?.projectVersion,
      });
      // console.log("ressss", res.data);
      if (res.status === 200) {
        alert(JSON.stringify(res.data));
        handleProjectStatus("in_progress", "1");
        setshowProjectStatusProgress(false);
      }
    } catch (error) {
      console.log("Error", error);
      alert("All operators feedbacks are pending");
    }
  };
  const handleStartTraining = () => {
    if (!selectedProjectVersion) {
      alert("Please select version first");
    } else {
      setStartTraining(true);
    }
  };

  const handleFinetuneStatus = async (status) => {
    try {
      const res = await axios.post(GET_FINETUNE_STATUS, {
        projectList: [activeProjectVersion?.projectId],
        status: status,
      });
      if (res.status === 200) {
        // console.log("ressssssssssssss", res.data);
        const response = await axios.post(FINETUNE_STATUS, {
          fineTuneProcessId: res.data.fineTuneProcessId,
        });
        // console.log("Finetune Status", res.data);
        if (res.status === 200) {
          alert(JSON.stringify(response.data));
        }
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleInprogressProjects = (project) => {
    setshowProjectStatusProgress(true);
    setActiveProjectVersion(project);
    setProjectFinetuneStatus(project.fineTuneStatus);
  };

  return (
    <div className="finetunebody">
      <div className="fintunebodycontent">
        <div className="leftfinetunebodycontent">
          <div className="upperleftfinetunebodycontent">
            <h2>{activeApp?.appName} Projects</h2>
          </div>
          <div className="bottomleftfinetunebodycontent">
            {renderProjectsList?.map((project) => (
              <div
                key={project?.projectId}
                className={`bottomleftfinetunebodycontentprojects ${
                  project.isSelected && "activeProject"
                }`}
              >
                <h2 onClick={() => handleclickProject(project)}>
                  {project?.projectName}
                </h2>
              </div>
            ))}
          </div>
        </div>

        <div className="rightfinetunebodycontent">
          <div className="rightfinetunebodycontentupper">
            <div className="rightfinetunebodycontentupperchild1">
              <h2>WIZARD - {activeProject?.projectName}</h2>
            </div>
            <div className="rightfinetunebodycontentupperchild2">
              <h2>What version would you like to take as base dataset?</h2>
              <select
                className="selectprojectversion"
                onChange={(e) => handleProjectVersion(e.target.value)}
              >
                <option style={{ display: "none" }}>Select</option>

                {renderProjectVersions?.map((version, index) => (
                  <option
                    key={version?.projectId || index}
                    value={version?.version}
                  >
                    {version?.version}
                  </option>
                ))}
                {/* <option>Default</option> */}
              </select>
            </div>
            <div className="rightfinetunebodycontentupperchild2">
              <h2>Do you like to update the weights?</h2>
              <div className="updateweightyesorno">
                <div
                  className={updateWeight ? "updateweights" : ""}
                  onClick={() => handleupdateWeight()}
                >
                  Yes
                </div>
                <div
                  onClick={() => {
                    setUpdateWeight(false);
                    setIsUpdateWeight("No");
                  }}
                >
                  No
                </div>
              </div>
            </div>

            <div className="rightfinetunebodycontentupperchild2">
              <h2>Do you want to merge the existing trace?</h2>
              <div className="updateweightyesorno">
                <div
                  className={mergeExistingTrace ? "updateweights" : ""}
                  onClick={() => {
                    if (!selectedProjectVersion) {
                      alert("Please select version first");
                    } else {
                      // setMergeExistingTrace(true);
                      setShowMergeTraceModal(true);
                    }
                  }}
                >
                  Yes
                </div>
                <div
                  onClick={() => {
                    setMergeExistingTrace(false);
                    setIsMergeTrace("No");
                  }}
                >
                  No
                </div>
              </div>
            </div>
            <div className="rightfinetunebodycontentupperchild2">
              <button
                className="starttraining"
                onClick={() => handleStartTraining()}
                // disabled={selectedProjectVersion ? false : true}
              >
                Start Training
              </button>
            </div>
          </div>
          <div className="rightfinetunebodycontentbottom">
            <div className="upperrightfinetunebodycontentbottom">
              <div
                className={status === "Pending" ? "updateweights" : ""}
                onClick={() => handleProjectStatus("Pending", 0)}
              >
                <h2> Pending</h2>
              </div>
              <div
                className={status === "in_progress" ? "updateweights" : ""}
                onClick={() => handleProjectStatus("in_progress", 1)}
              >
                <h2>In Progress</h2>
              </div>
              <div
                className={status === "Completed" ? "updateweights" : ""}
                onClick={() => handleProjectStatus("Completed", 2)}
              >
                <h2>Completed</h2>
              </div>
            </div>
            {status === "" && (
              <div className="bottomrightfinetunebodycontentbottom">
                Please click any of the above three options
              </div>
            )}
            {status === "Pending" && (
              <div className="projectslistandversions">
                {showProjectStatus ? (
                  <div className="waitfordatabasegenerating">
                    <h2>
                      Please wait while dataset is being generated for{" "}
                      {activeProjectVersion?.projectVersion}
                    </h2>
                    <button onClick={() => setShowProjectStatus(false)}>
                      Back
                    </button>
                  </div>
                ) : (
                  pendingProjectsList?.map((project) => (
                    <div
                      key={project.projectId}
                      className="pendingprojectsfinetuning"
                      onClick={() => {
                        setShowProjectStatus(true);
                        setActiveProjectVersion(project);
                      }}
                    >
                      <h2>{projectDetailData.projectName}</h2>
                      <h2>{project.projectVersion}</h2>
                    </div>
                  ))
                )}
              </div>
            )}
            {status === "in_progress" && (
              <div className="projectslistandversions">
                {showProjectStatusProgress ? (
                  projectFinetuneStatus === "1.5" ? (
                    <div className="waitfordatabasegenerating">
                      <h2>
                        Dataset has been generated, also finetuning started for
                        version {activeProjectVersion?.projectVersion}
                      </h2>
                      <div>
                        <button
                          onClick={() => {
                            handleProjectVersionShowStatus("in_progress", 1);
                          }}
                        >
                          Show Status
                        </button>
                        <button
                          onClick={() => handleFinetuneStatus("in_progress", 1)}
                        >
                          Show Finetune Status
                        </button>
                        {/* {showFinetuneStarted && (
                          <button
                            onClick={() => setShowProgressFinetuningModal(true)}
                          >
                            Run
                          </button>
                        )} */}
                        <button
                          onClick={() => setshowProjectStatusProgress(false)}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="waitfordatabasegenerating">
                      <h2>
                        Dataset has been generated, finetuning needs to be done
                        for {activeProjectVersion?.projectVersion}
                      </h2>
                      <div>
                        {/* <button
                        onClick={() => {
                          handleProjectVersionShowStatus("in_progress", 1);
                        }}
                      >
                        Show Status
                      </button>
                      <button onClick={() => handleFinetuneStatus()}>
                        Show Finetune Status
                      </button> */}

                        <button
                          onClick={() => setShowProgressFinetuningModal(true)}
                        >
                          Run
                        </button>

                        <button
                          onClick={() => setshowProjectStatusProgress(false)}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  )
                ) : (
                  // )
                  inprogressProjectsList?.map((project) => (
                    <div
                      key={project.projectId}
                      className="pendingprojectsfinetuning"
                      onClick={() => {
                        handleInprogressProjects(project);
                      }}
                    >
                      <h2>{projectDetailData.projectName}</h2>
                      <h2>{project.projectVersion}</h2>
                      {/* {console.log(project)} */}
                      {/* {project.projectId === activeProjectVersion?.projectId &&
                      showFinetuneStarted ? (
                        <h2>Finetune Started</h2>
                      ) : (
                        <h2>Finetune Not started</h2>
                      )} */}
                      {project.fineTuneStatus === "1" ? (
                        <h2>Finetune Not Started</h2>
                      ) : project.fineTuneStatus === "1.5" ? (
                        <h2>Finetune started</h2>
                      ) : (
                        <h2>Finetune Not Started</h2>
                      )}
                    </div>
                  ))
                )}
              </div>
            )}
            {status === "Completed" && (
              <div className="projectslistandversions">
                {showProjectStatusCompleted ? (
                  <div className="waitfordatabasegenerating">
                    <h2>
                      Finetuning is completed for
                      {activeProjectVersion?.projectVersion}
                    </h2>
                    <button
                      onClick={() => setShowProjectStatusCompleted(false)}
                    >
                      Back
                    </button>
                  </div>
                ) : (
                  completedProjectsList?.map((project) => (
                    <div
                      key={project.projectId}
                      className="pendingprojectsfinetuning"
                      onClick={() => {
                        setShowProjectStatusCompleted(true);
                        setActiveProjectVersion(project);
                      }}
                    >
                      <h2>{projectDetailData.projectName}</h2>
                      <h2>{project.projectVersion}</h2>
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
          {showUpdateWeightsModal && (
            <UpdateWeightsModal
              show={showUpdateWeightsModal}
              setshow={setShowUpdateWeightsModal}
              updatedUsersList={updatedUsersList}
              setUpdatedUsersList={setUpdatedUsersList}
              setUpdateWeight={setUpdateWeight}
              setIsUpdateWeight={setIsUpdateWeight}
            />
          )}
          {startTraining && (
            <StartTrainingModal
              show={startTraining}
              setshow={setStartTraining}
              activeProject={projectVersionsdata}
              activeApp={activeApp}
              orgId={userDetails?.orgId}
              updatedUsersList={updatedUsersList}
              collectedMergeTraces={collectedMergeTraces}
              isUpdateWeight={isUpdateWeight}
              ismergeTrace={ismergeTrace}
            />
          )}
          {showProgressFinetuningModal && (
            <ProgressFinetuningModal
              show={showProgressFinetuningModal}
              setshow={setShowProgressFinetuningModal}
              handleRunFinetune={handleRunFinetune}
              // setShowFinetuneStarted={setShowFinetuneStarted}
            />
          )}
          {showMergeTraceModal && (
            <MergeTraceModal
              show={showMergeTraceModal}
              setshow={setShowMergeTraceModal}
              traceIDList={projectDetailData?.traceId}
              traceData={traceData}
              projectVersionsList={projectVersionsdata.traceId}
              setTracesData={setTracesData}
              setCollectedMergeTraces={setCollectedMergeTraces}
              setMergeExistingTrace={setMergeExistingTrace}
              setIsMergeTrace={setIsMergeTrace}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FinetuneBody;
