import * as React from "react";
// import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
// import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import "./ChangePasswordModal.css";
import { useState } from "react";
import axios from "axios";
import { EDIT_USERPassword } from "../../util/ApiEndPoints";

export default function ChangePasswordModal({ show, setshow, loggedUserData }) {
  const [oldpassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  // console.log("loggeddd", loggedUserData);

  const changeUserPassword = async () => {
    if (newPassword !== confirmNewPassword) {
      alert("New Password And Confirm New Password Are Different");
      return;
    }
    if (oldpassword !== loggedUserData?.password) {
      alert("Old Password Is Incorrect ");
      return;
    }
    try {
      const res = await axios.post(EDIT_USERPassword, {
        orgID: loggedUserData?.orgId,
        authKey: loggedUserData?.authKey,
        emailId: loggedUserData?.emailId,
        password: newPassword,
      });
      if (res.status === 200) {
        alert(res.data.message);
        localStorage.setItem(
          "userDetails",
          JSON.stringify({
            ...loggedUserData,
            password: newPassword,
          })
        );
        setshow(false);
      }
    } catch (error) {
      console.log("Error", error);
    }
  };
  //   console.log("args:", args);
  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={show}
        onClose={() => setshow(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          //   background: "yellow",
        }}
        // hideBackdrop="true"
      >
        <Sheet
          variant="outlined"
          sx={{
            display: "flex",
            flexDirection: "column",
            // width: "500px",
            borderRadius: "md",
            // pt: 4,
            pb: 0,
            boxShadow: "sm",
            background: "white",
            // padding: "20px",
            // fontFamily: "Noto Sans Mono" || "monospace",
            // backdropFilter: "blur",
          }}
        >
          <div className="changepasswordmodal">
            <div className="oldpassword">
              <p>Old Password</p>
              <input
                value={oldpassword}
                onChange={(e) => setOldPassword(e.target.value)}
                type="text"
              />
            </div>
            <div className="oldpassword">
              <p>New Password</p>
              <input
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                type="text"
              />
            </div>
            <div className="oldpassword">
              <p>Confirm Password</p>
              <input
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                type="text"
              />
            </div>
            <div className="editpassowrdbuttons">
              <button onClick={() => setshow(false)}>Exit</button>
              <button
                disabled={
                  oldpassword && newPassword && confirmNewPassword
                    ? false
                    : true
                }
                onClick={() => changeUserPassword()}
              >
                Save
              </button>
            </div>
          </div>
        </Sheet>
      </Modal>
    </>
  );
}
