import React, { useEffect } from "react";
import "./PMDashboardBody.css";
// import applogo from "../../Assets/applogo.png";
// import { FaAngleRight } from "react-icons/fa6";
import { FaBars } from "react-icons/fa";
import { useState } from "react";
import { useSelector } from "react-redux";
// import { getAllBaseProjects } from "../../Store/AllProjectsSlice";
// import { allowCreateProject } from "../../Store/UserConditonsSlice";
// import { useNavigate } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";
// import { FaGreaterThan } from "react-icons/fa";
// import { FaLessThan } from "react-icons/fa";
import _ from "lodash";
import ProjectVersionsTree from "../subcomponents/pmdashboardSubcomponents/ProjectVersionsTree";
import axios from "axios";
import { ABTEST_DOWNLOAD, GET_PROJECTDETAILS } from "../../util/ApiEndPoints";
import AppDetailPmdashboard from "../subcomponents/pmdashboardSubcomponents/AppDetailPmdashboard";
import IfProjectCreated from "../subcomponents/IfProjectCreated";
import EditProject from "./EditProject";
import ABTestingTree from "./ABTestingTree";
import ABTestingStartedModal from "../subcomponents/pmdashboardSubcomponents/ABTestingStartedModal";
import ReportModal from "../subcomponents/pmdashboardSubcomponents/ReportModal";
import ReportCreatedModal from "../subcomponents/pmdashboardSubcomponents/reportCreatedModal";

const PMDashboardBody = ({
  // appList,
  setViewProjectDetail,
  setActiveProject,
  activeProject,
  activeApp,
  // setActiveApp,
}) => {
  const savedUserDetails = localStorage.getItem("userDetails");
  const userDetails = JSON.parse(savedUserDetails);
  // console.log("activeProject", activeProject);
  const [showSelect, setShowSelect] = useState(false);
  const { baseProjectsList } = useSelector((state) => state?.projectsList);
  const [baseProjects, setBaseProjects] = useState([]);
  const [handleClickProjecId, serthandleClickedProjectId] = useState(null);
  const [startABTesting, setStartABTesting] = useState(false);
  // const [selectedProject, setSelectedProject] = useState(null);
  const [isProjectSelected, setIsProjectSelected] = useState(false);
  const [allReportsData, setAllReportsData] = useState([]);
  // const [changeProjectVersionDetails, setChangeProjectVersionDetails] =
  //   useState(false);
  const [clickedCircle, setclickedCircle] = useState(false);
  // const [isProjectVersion, setIsProjectVersion] = useState(false);
  const [isProjectCreated, setIsProjectCreated] = useState(false);
  const [clickedTab, setClickedTab] = useState("PVD");
  const [appDetailClicked, setAppDetailClicked] = useState(false);
  const [abTestingClicked, setabTestingClicked] = useState(false);
  const [isProjectedit, setIsProjectedit] = useState(false);
  const [showReportModal, setShowReportModal] = useState(false);
  const [clickedProjectVersionDetails, setClickedProjectVersionDetails] =
    useState(true);
  const [generateReport, setGenerateReport] = useState(false);
  const [circles, setCircles] = useState([]);
  const [abTestingStartedModal, setabTestingStartedModal] = useState(false);
  const [isSelectSecondCircle, setisSelectSecondCircle] = useState(false);
  const [firstClickedCircle, setFirstClickedCircle] = useState();
  const [secondSelectedCircle, setSecondSelectedCircle] = useState();
  const [makeReport, setMakeReport] = useState(false);
  const [reportCreated, setReportCreated] = useState(false);
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const dispatch = useDispatch();

  // console.log("Applist", appList);
  // console.log("baseProejctsList", baseProejctsList);
  // console.log("baseProjects", baseProjects);
  // console.log("circles", circles);
  // console.log("firstClickedCircle", firstClickedCircle);
  // console.log("secondSelectedCircle", secondSelectedCircle);
  // console.log("generateReport", generateReport);
  // console.log("baseProjectsList", baseProjectsList);
  // console.log("Activeapp", activeApp);
  useEffect(() => {
    if (baseProjectsList) {
      const fetchBaseprojects = baseProjectsList?.map((base) => {
        return {
          ...base,
          isSelected: false,
        };
      });
      setBaseProjects(fetchBaseprojects);
    }
  }, [baseProjectsList]);

  // const handleClickApp = (app) => {
  //   dispatch(
  //     getAllBaseProjects({ orgId: userDetails?.orgId, appId: app.app_id })
  //   );
  //   setToggleApplist(true);
  // };

  const handleEditProject = (project) => {
    setShowSelect(false);
    setIsProjectedit(true);
    setActiveProject(project);
  };

  const handleShowSelectAndProjects = (project) => {
    _?.map(baseProjects, (base) => {
      if (base?.projectId === project?.projectId) {
        setShowSelect(!showSelect);
        serthandleClickedProjectId(project.projectId);
      }
    });
  };

  const populateCircles = async (projectId) => {
    try {
      const customHeaders = {
        Authorization: `Bearer ${userDetails.access_token}`,
      };

      const res = await axios.post(
        GET_PROJECTDETAILS,
        { projectId, orgId: userDetails?.orgId },
        { headers: customHeaders }
      );

      // console.log("response", res.data);

      let connections = res.data.otherVersions.map(
        (version) => version.projectId
      );

      // Update the circles with the base project
      setCircles((prevCircles) => [
        ...prevCircles,
        {
          id: res.data.projectID,
          label: res.data.version,
          connections,
        },
      ]);

      // Recursively populate circles for other versions
      for (const version of res.data.otherVersions) {
        await populateCircles(version.projectId);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const projectSelected = async (project) => {
    // console.log("selectedProject", project);
    setIsProjectSelected(true);
    setShowSelect(false);

    // Clear the circles array when a new project is selected
    setCircles([]);

    // Populate circles for the selected project
    await populateCircles(project.projectId);
  };

  const handleviewProjectDetail = (project) => {
    // console.log("projecttttttt", project);
    setViewProjectDetail(true);
    setActiveProject(project);
  };
  const handleAppDetailsClicked = () => {
    setClickedProjectVersionDetails(false);
    setAppDetailClicked(true);
    setabTestingClicked(false);
    // setToggleApplist(true);
  };

  const handleabTestingClicked = () => {
    setClickedProjectVersionDetails(false);
    setAppDetailClicked(false);
    setabTestingClicked(true);
    // setToggleApplist(true);
  };

  const handleABTestingButtonClicked = () => {
    setStartABTesting(true);
  };

  const handleShowallReports = async () => {
    setShowReportModal(true);
    try {
      const res = await axios.post(ABTEST_DOWNLOAD, {
        orgId: userDetails?.orgId,
      });
      console.log("datatat", res.data);
      if (res.status === "200") {
        setAllReportsData(res.data);
      }
    } catch (error) {
      console.error("error", error);
    }
  };
  // const handleClickedOpenicon = () => {
  //   setToggleApplist(false);
  // };

  if (isProjectCreated) {
    return (
      <IfProjectCreated
        setIsProjectCreated={setIsProjectCreated}
        setShowSelect={setShowSelect}
        activeApp={activeApp}
      />
    );
  }
  if (isProjectedit) {
    return (
      <EditProject
        setIsProjectedit={setIsProjectedit}
        setShowSelect={{ setShowSelect }}
        activeProject={activeProject}
        activeApp={activeApp}
      />
    );
  }
  return (
    <div className="pmdashboard">
      <div className="leftpmdashboard">
        <div className={"leftpmdashboardmainchild"}>
          <div className={clickedTab === "PVD" && "clickedpmtab"}>
            <h3
              onClick={() => {
                setClickedProjectVersionDetails(true);
                setAppDetailClicked(false);
                setabTestingClicked(false);
                setClickedTab("PVD");
              }}
            >
              Project Version Details
            </h3>
          </div>
          <div
            className={clickedTab === "ABT" && "clickedpmtab"}
            onClick={() => {
              handleabTestingClicked();
              setClickedTab("ABT");
            }}
          >
            <h3>A/B Testing</h3>
          </div>
          <div
            className={clickedTab === "AD" && "clickedpmtab"}
            onClick={() => {
              handleAppDetailsClicked();
              setClickedTab("AD");
            }}
          >
            <h3>App Details</h3>
          </div>
        </div>
      </div>
      {appDetailClicked && (
        <div className={`rightpmdashboard`}>
          <AppDetailPmdashboard />
        </div>
      )}
      {clickedProjectVersionDetails && (
        <div className={`rightpmdashboard`}>
          {/* {toggleApplist && */}
          {isProjectSelected ? (
            <div className="upperrightpmdashboard2">
              <div className="upperrightpmdashboardprojecttext2">
                <h1>Project List</h1>
                <FaChevronDown
                  size={30}
                  cursor={"pointer"}
                  onClick={() => setIsProjectSelected(false)}
                />
              </div>
              {/* <div style={containerStyle}> */}
              <ProjectVersionsTree
                setclickedCircle={setclickedCircle}
                clickedCircle={clickedCircle}
                circles={circles}
              />
              {/* </div> */}
            </div>
          ) : (
            <div className="upperrightpmdashboard">
              <div className="upperrightpmdashboardprojecttext">
                <h2>Project List</h2>
                <button onClick={() => setIsProjectCreated(true)}>
                  Create
                </button>
              </div>
              <div className="upperrightpmdashboardprojectlists">
                {baseProjects?.map((project, index) => (
                  <div
                    key={project.projectId}
                    className="projectslistpmdashboard"
                    // onClick={() => projectSelected(project)}
                  >
                    <h2 className="someheading">{project?.projectName}</h2>
                    <h2 className="someheading">{project?.rlhfObjective}</h2>
                    <div className="projectitembar">
                      <FaBars
                        size={20}
                        cursor={"pointer"}
                        onClick={() => handleShowSelectAndProjects(project)}
                      />
                      {showSelect &&
                        project.projectId === handleClickProjecId && (
                          <div className="selectandeditprojectpmdashboard">
                            <div onClick={() => projectSelected(project)}>
                              Select
                            </div>
                            <hr />
                            {/* <Link
                                to={"/user/adminanduserprojectslist"}
                                className="projectlinkbutton"
                                onClick={onCreateProject}
                              > */}
                            {/* <div onClick={() => setIsProjectCreated(true)}>
                                Create Project
                              </div> */}
                            {/* </Link> */}

                            <div
                              onClick={() => handleviewProjectDetail(project)}
                            >
                              View Detail
                            </div>
                            <hr />
                            <div onClick={() => handleEditProject(project)}>
                              Edit Project
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* {toggleApplist && ( */}
          <div className="bottomrightpmdashboard">
            {isProjectSelected ? (
              clickedCircle ? (
                // changeProjectVersionDetails ? (
                <div className="selectedprojectversiondetails">
                  {/* <div>
                    <FaLessThan
                      size={40}
                      className="nextprojectversiondetailsicon"
                      onClick={() => setChangeProjectVersionDetails(false)}
                    />
                  </div> */}
                  <div className="selectedprojectversiondetailsclild">
                    <h1>User Details</h1>
                    <h1>Date</h1>
                    {/* <h1>Date</h1> */}
                    <h1>Status of the Project Version</h1>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "2rem",
                        gap: "2rem",
                        alignItems: "center",
                      }}
                    >
                      <h1>Trace ID download</h1>{" "}
                      <button
                        style={{
                          border: "none",
                          background: "#d8d8d8",
                          color: "blue",
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        Click here to download
                      </button>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        gap: "20px",
                      }}
                    >
                      <button
                        className="backbuttonforprojectversion"
                        onClick={() => setclickedCircle(false)}
                      >
                        Back
                      </button>
                      <button
                        className="backbuttonforprojectversion"
                        // onClick={() => setChangeProjectVersionDetails(false)}
                      >
                        Start Deploying
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <h1 style={{ fontSize: 30 }}>
                  Choose a project version to proceed
                </h1>
              )
            ) : (
              <h1 style={{ fontSize: 30 }}>Choose a project to proceed </h1>
            )}
          </div>
          {/* )} */}
        </div>
      )}
      {abTestingClicked && (
        <div className={`rightpmdashboard`}>
          {/* {toggleApplist && */}
          {isProjectSelected ? (
            <div className="upperrightpmdashboard2">
              <div className="upperrightpmdashboardprojecttext2">
                <h1>Project List</h1>
                <FaChevronDown
                  size={30}
                  cursor={"pointer"}
                  onClick={() => setIsProjectSelected(false)}
                />
              </div>
              {/* <div style={containerStyle}> */}
              <ABTestingTree
                setclickedCircle={setclickedCircle}
                circles={circles}
                clickedCircle={clickedCircle}
                // clickCircle={() => handleClickCircle()}
                startABTesting={startABTesting}
                setStartABTesting={setStartABTesting}
                setabTestingStartedModal={setabTestingStartedModal}
                generateReport={generateReport}
                setGenerateReport={setGenerateReport}
                setisSelectSecondCircle={setisSelectSecondCircle}
                setSecondSelectedCircle={setSecondSelectedCircle}
                setFirstClickedCircle={setFirstClickedCircle}
                setMakeReport={setMakeReport}
                secondSelectedCircle={secondSelectedCircle}
                firstClickedCircle={firstClickedCircle}
              />
              {/* </div> */}
            </div>
          ) : (
            <div className="upperrightpmdashboard">
              <div className="upperrightpmdashboardprojecttext">
                <h2>Project List</h2>
                <button onClick={() => setIsProjectCreated(true)}>
                  Create
                </button>
              </div>
              <div className="upperrightpmdashboardprojectlists">
                {baseProjects?.map((project, index) => (
                  <div
                    key={project.projectId}
                    className="projectslistpmdashboard"
                    // onClick={() => projectSelected(project)}
                  >
                    <h2 className="someheading">{project?.projectName}</h2>
                    <h2 className="someheading">{project?.rlhfObjective}</h2>
                    <div className="projectitembar">
                      <FaBars
                        size={20}
                        cursor={"pointer"}
                        onClick={() => handleShowSelectAndProjects(project)}
                      />
                      {showSelect &&
                        project.projectId === handleClickProjecId && (
                          <div className="selectandeditprojectpmdashboard">
                            <div onClick={() => projectSelected(project)}>
                              Select
                            </div>
                            <hr />
                            <div
                              onClick={() => handleviewProjectDetail(project)}
                            >
                              Veiw Detail
                            </div>
                            <hr />
                            <div onClick={() => handleEditProject(project)}>
                              Edit Project
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* {toggleApplist && */}
          {makeReport ? (
            <div className="makereportpmdash">
              <button
                onClick={() => {
                  setReportCreated(true);
                  setGenerateReport(false);
                  setMakeReport(false);
                  setFirstClickedCircle("");
                  setSecondSelectedCircle("");
                  setisSelectSecondCircle(false);
                }}
              >
                Make Report
              </button>
            </div>
          ) : (
            <div className="bottomrightpmdashboard">
              {isProjectSelected ? (
                startABTesting ? (
                  <div className="abselectedprojectversiondetails">
                    <h1>Select a project version to start A/B testing</h1>
                  </div>
                ) : generateReport ? (
                  <div className="abselectedprojectversiondetails">
                    {isSelectSecondCircle ? (
                      <h1
                        style={{
                          width: "100%",
                          // background: "yellow",
                          textAlign: "center",
                        }}
                      >
                        Choose second project version
                      </h1>
                    ) : (
                      <h1
                        style={{
                          width: "100%",
                          // background: "yellow",
                          textAlign: "center",
                        }}
                      >
                        Choose first project version
                      </h1>
                    )}
                  </div>
                ) : (
                  <div className="abselectedprojectversiondetails">
                    {/* <div className="abtestingdashboard"> */}
                    <h1>Welcome to A/B Testing Dashboard</h1>
                    <div className="abtestingdashboardbuttons">
                      <button onClick={() => handleABTestingButtonClicked()}>
                        Start A/B Testing
                      </button>
                      <button onClick={() => setGenerateReport(true)}>
                        Generate Report
                      </button>
                      <button onClick={() => handleShowallReports()}>
                        Show All Reports
                      </button>
                    </div>
                    {/* </div> */}
                  </div>
                )
              ) : (
                <h1 style={{ fontSize: 30 }}>Choose a project to proceed </h1>
              )}
            </div>
          )}
          {abTestingStartedModal && (
            <ABTestingStartedModal
              show={abTestingStartedModal}
              setshow={setabTestingStartedModal}
            />
          )}
          {showReportModal && (
            <ReportModal
              show={showReportModal}
              setshow={setShowReportModal}
              allReportsData={allReportsData}
            />
          )}
          {reportCreated && (
            <ReportCreatedModal
              show={reportCreated}
              setshow={setReportCreated}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default PMDashboardBody;
