import * as React from "react";
// import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
// import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";

export default function BasicModal({ show, setshow, Component, ...args }) {
  // console.log("args:", args);
  return (
    <>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={show}
        onClose={() => setshow(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // background: "yellow",
        }}
        // hideBackdrop="true"
      >
        <Sheet
          variant="outlined"
          sx={{
            display: "flex",
            flexDirection: "column",
            // width: "500px",
            borderRadius: "md",
            // pt: 4,
            pb: 0,
            boxShadow: "sm",
            background: "white",
            // fontFamily: "Noto Sans Mono" || "monospace",
            // backdropFilter: "blur",
          }}
        >
          <Component setshow={setshow} otherArgs={args} />
        </Sheet>
      </Modal>
    </>
  );
}
