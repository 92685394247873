import React, { useEffect, useRef, useState } from "react";

const ProjectVersionsTree = ({
  setclickedCircle,
  circles,
  clickedCircle,
  clickCircle,
  startABTesting,
  setStartABTesting,
  setabTestingStartedModal,
  generateReport,
  setFirstClickedCircle,
  setGenerateReport,
  setisSelectSecondCircle,
  setSecondSelectedCircle,
  setMakeReport,
  secondSelectedCircle,
  firstClickedCircle,
}) => {
  const containerRef = useRef();
  const [containerWidth, setContainerWidth] = useState(0);
  const [count, setCount] = useState(0);
  // const [clickedCircle, setClickedCircle] = useState();
  // console.log("couunt", count);
  useEffect(() => {
    // Update the width of the SVG to match the container's current width
    const updateContainerWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };

    // Call when component mounts and add event listener for window resize
    updateContainerWidth();
    window.addEventListener("resize", updateContainerWidth);

    // Clean up event listener
    return () => {
      window.removeEventListener("resize", updateContainerWidth);
    };
  }, []);

  // Adjust cx for each circle dynamically based on its index
  const adjustCirclePositions = (circles) => {
    return circles.map((circle, index) => ({
      ...circle,
      cx: 30 + index * 60, // Horizontal space between circles
      cy: parseInt(circle.label.split(".")[0], 10) * 50, // Vertical space based on "level"
    }));
  };

  // Add adjusted positions to circles
  const positionedCircles = adjustCirclePositions(circles);

  // Calculate the height needed for the SVG based on the number of levels
  const svgHeight =
    positionedCircles.reduce((acc, circle) => Math.max(acc, circle.cy), 0) + 50; // Additional space
  // console.log("positionedCircles", positionedCircles);
  // console.log("clickedCircle", clickedCircle);
  const handleClickedProjectVersion = (circle) => {
    // setIsProjectVersionSelected(!isProjectVersionSelected);
    if (startABTesting) {
      setclickedCircle(circle);
      setabTestingStartedModal(true);
      setStartABTesting(false);
      setclickedCircle("");
    } else if (generateReport && count === 0) {
      // setclickedCircle(circle);
      setisSelectSecondCircle(true);
      setCount(1);
      setFirstClickedCircle(circle);
      setFirstClickedCircle(circle);
    } else if (generateReport && count === 1) {
      // setclickedCircle(circle);
      setSecondSelectedCircle(circle);
      setMakeReport(true);
      setCount(0);
    } else {
      alert("Action Not Available");
    }
  };

  return (
    <div
      ref={containerRef}
      style={{
        width: "100%",
        height: "100%",
        overflow: "auto",
        border: "1px solid black",
      }}
    >
      <svg width={containerWidth} height={svgHeight}>
        {/* Render the full-width lines */}
        {positionedCircles.map((circle) => (
          <line
            key={`line-${circle.id}`}
            x1="0"
            y1={circle.cy}
            x2={containerWidth} // Line stretches across the width of the SVG
            y2={circle.cy}
            stroke="#A2A2A2"
            strokeWidth="1"
          />
        ))}

        {/* Render the circles */}
        {positionedCircles.map((circle) => (
          <g
            key={circle.id}
            onClick={() => handleClickedProjectVersion(circle)}
          >
            <circle
              cx={circle.cx}
              cy={circle.cy}
              r="20"
              fill={
                clickedCircle?.id === circle?.id ||
                secondSelectedCircle?.id === circle?.id ||
                firstClickedCircle?.id === circle?.id
                  ? "#9b9b9b"
                  : "white"
              }
              stroke="black"
              strokeWidth="2"
            />
            <text
              x={circle.cx}
              y={circle.cy}
              textAnchor="middle"
              fill="black"
              dy=".3em"
              fontSize="12"
              cursor="pointer"
            >
              {circle.label}
            </text>
          </g>
        ))}
      </svg>
    </div>
  );
};

export default ProjectVersionsTree;
