import React, { useEffect } from "react";
import "./MainDashboardMainContent.css";
import MainDashboardNavbar from "./subcomponents/MainDashboardNavbar";
import MainDashboardMainBody from "./subcomponents/MainDashboardMainBody";
import { useDispatch } from "react-redux";
import { getAppList } from "../Store/AppListSlice";
const MainDashboardMainContent = () => {
  const savedUserDetails = localStorage.getItem("userDetails");
  const userDetails = JSON.parse(savedUserDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    const payload = { orgId: userDetails?.orgId };
    dispatch(getAppList({ payload, dispatch }));
  }, [dispatch, userDetails?.orgId]);

  return (
    <div className="maindashboardmaincontent">
      <MainDashboardNavbar />
      <MainDashboardMainBody />
    </div>
  );
};

export default MainDashboardMainContent;
