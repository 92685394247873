import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { COMMENT_FEEDBACK, GET_FEEDBACKS } from "../util/ApiEndPoints";

export const getUsersFeedbacks = createAsyncThunk(
  "getUsersFeedbacks",
  async (payload) => {
    const savedUserDetails = localStorage.getItem("userDetails");
    const userDetails = JSON.parse(savedUserDetails);
    const customHeaders = {
      Authorization: `Bearer ${userDetails?.access_token}`,
      // Add more custom headers if needed
    };
    // console.log("getfeedbackpayload??", payload);
    try {
      const res = await axios.post(GET_FEEDBACKS, payload, {
        headers: customHeaders,
      });
      // console.log("res???", res);
      if (res.status === 200) {
        // console.log("res???", res.data);
        return res.data;
      }
    } catch (error) {
      console.log("Error:", error);
    }
  }
);

export const sendUserFeedback = createAsyncThunk(
  "sendUserFeedback",
  async (payload) => {
    const { feedback, dispatch } = payload;
    const savedUserDetails = localStorage.getItem("userDetails");
    const userDetails = JSON.parse(savedUserDetails);
    const customHeaders = {
      Authorization: `Bearer ${userDetails.access_token}`,
      // Add more custom headers if needed
    };
    // console.log("feedbackPayload", feedback);
    const res = await axios.post(COMMENT_FEEDBACK, feedback, {
      headers: customHeaders,
    });
    // console.log("res>>||", res.data);
    if (res.status === 200) {
      dispatch(
        getUsersFeedbacks({
          traceId: feedback.traceId,
          appId: feedback.appId,
          orgId: userDetails?.orgId,
        })
      );
      alert(res.data.message);
      // return res.data;
    }
  }
);

const userFeedbackSlice = createSlice({
  name: "userFeedbacks",
  initialState: {
    userFeedbacks: null,
    status: "idle",
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersFeedbacks.pending, (state) => {
        state.status = "Pending";
      })
      .addCase(getUsersFeedbacks.fulfilled, (state, { payload }) => {
        state.status = "Successed";
        state.userFeedbacks = payload;
      })
      .addCase(getUsersFeedbacks.rejected, (state, action) => {
        state.status = "Failed";
        state.error = action.error.message;
      });
  },
});
export default userFeedbackSlice.reducer;
