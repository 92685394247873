import React from "react";
import "./DashboardBody.css";
import { Link } from "react-router-dom";
import image1 from "../../Assets/image1.png";
import image2 from "../../Assets/image2.png";
import image3 from "../../Assets/image3.png";

const DashboardBody = () => {
  return (
    <div className="dashboardbody">
      <div className="dashboardbodycontent">
        <div className="dashboardbodycontentyojn">
          <h1>YOJN AI</h1>
        </div>
        <div>
          <h1>🔔 Attention AI Product Leaders </h1> 
          <h2>
            Integrate your model, in your product, on your infrastructure 🚀
          </h2>
        </div>
       </div> 
        {/* <div>
          <h2>Create | Test | Deploy | Improve</h2>
        </div>
        <Link
          to={
            "https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ2txrOjvrfgkHh5u6mHrsEDrFKisnJjIOsbszChbBRHC6B9eLZSX4zr006y7izItbJ9-KJr0TGS"
          }
          className="bookslotbutton"
        >
          Book a slot for Demo
        </Link>
      </div> */}
      <div className="dashboardbodycontentfooter">
        <div className="dashboardbodycontentfooterchild">
          <div className="dashboardbodycontentfooterleftchild">
            <h1>Self Hosted Solution</h1>
            <h3>
              We just make it simple to version control & deploy those weights &
              configs. That's all!(Your model, your infrastructure, your data,
              your control)
            </h3>
          </div>
          <div className="dashboardbodycontentfootermiddleChild">
          <div>
          <h2>Create | Test | Deploy | Improve</h2>
        </div>
        <Link
          to={
            "https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ2txrOjvrfgkHh5u6mHrsEDrFKisnJjIOsbszChbBRHC6B9eLZSX4zr006y7izItbJ9-KJr0TGS"
          }
          className="bookslotbutton"
        >
          Book a slot for Demo
        </Link>
      </div>
          <div className="dashboardbodycontentfooterrightchild">
            <img src={image1} alt="image1" />
          </div>
        </div>
        <div className="dashboardbodycontentfooterchild">
          <div className="dashboardbodycontentfooterleftchildswap">
            <div className="dashboardbodycontentfooterleftchildswapimg">
              <img src={image3} alt="image3" />
            </div>
            <div className="dashboardbodycontentfooterleftchildswapcontent">
            <h1>Production Ready</h1>
            <h3>
              Supports Kubernetes for your scaling & deployment needs. Supports
              callbacks directly with REST APIs or npm or pip. (More to come
              soon ...)
            </h3>
          </div>
          </div>
          <div className="dashboardbodycontentfooterrightchildswap"> 
            <div className="dashboardbodycontentfooterrightchildswapcontent">
            <h1>You'll get clear visibility of your cloud spend as well </h1>
            <h3>
              This is your GPU cost. You pay this directly to your cloud
              provider, not us. We just make it transparent. That's all!
              (Depends on your use case but usually ~ $2.5/hr on AWS, for upto 3
              YOJN Apps, does the trick)
             </h3> 
            </div>
            <div className="dashboardbodycontentfooterrightchildswapimg">
            <img src={image2} alt="image2" />
          </div>
          </div>
        </div>
        {/* <div className="dashboardbodycontentfooterchild">
          <div className="dashboardbodycontentfooterleftchild">
            <h1>Production Ready</h1>
            <h3>
              Supports Kubernetes for your scaling & deployment needs. Supports
              callbacks directly with REST APIs or npm or pip. (More to come
              soon ...)
            </h3>
          </div>
          <div className="dashboardbodycontentfooterrightchild">
            <img src={image2} alt="image2" />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default DashboardBody;
