import React, { useState } from "react";
import "./ViewProjectDetail.css";
import DeleteProjectModal from "../subcomponents/pmdashboardSubcomponents/DeleteProjectModal";

const ViewProjectDetail = ({ activeProject, setViewProjectDetail }) => {
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);
  // console.log("activeprojecttttt", activeProject);
  return (
    <div className="projectviewdetail">
      <div className="upperprojectviewdetail">
        <div className="upperprojectviewdetailfields">
          <h1>Project Name :</h1>
          <h2>{activeProject?.projectName}</h2>
        </div>
        <div className="upperprojectviewdetailfields">
          <h1>RLHF Objective : </h1>
          <h2>{activeProject?.rlhfObjective}</h2>
        </div>
        <div className="upperprojectviewdetailfields">
          <h1>Current Project Version :</h1>
        </div>
      </div>
      <div className="lowerprojectviewdetail">
        <div className="upperlowerprojectviewdetail">
          <div
            className="userdetailsheadings"
            style={{
              borderBottom: "1px solid rgba(0, 0, 0, 0.645)",
              paddingBottom: "10px",
            }}
          >
            <div>Email ID</div>
            <div>First Name</div>
            <div>Last Name</div>
            <div>Role</div>
            <div>Weightage</div>
          </div>
          <div className="scrollableuserdetails">
            {activeProject?.users?.map((user, index) => (
              <div key={index} className="userdetailsheadingswithborder">
                <div>{user.emailId}</div>
                <div>{user.userFirstName}</div>
                <div>{user.userLastName}</div>
                <div className="rolewithdropdown">{user.title}</div>
                <div>{user.weightage}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="bottomlowerprojectviewdetail">
          <button
            onClick={() => {
              setViewProjectDetail(false);
            }}
          >
            Back
          </button>
          <button onClick={() => setShowDeleteProjectModal(true)}>
            Delete Project
          </button>
        </div>
      </div>
      {showDeleteProjectModal && (
        <DeleteProjectModal
          show={showDeleteProjectModal}
          setshow={setShowDeleteProjectModal}
        />
      )}
    </div>
  );
};

export default ViewProjectDetail;
