import React, { useState } from "react";
import MainDashboardNavbar from "../components/subcomponents/MainDashboardNavbar";
import yojn from "../Assets/yojn.svg";
import user from "../Assets/defaultuser.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { EDIT_USER } from "../util/ApiEndPoints";
const EditUserprofile = () => {
  const savedUserDetails = localStorage.getItem("userDetails");
  const loggedUserData = JSON.parse(savedUserDetails);
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState(
    loggedUserData?.userFirstName || ""
  );
  const [lastName, setLastName] = useState(loggedUserData?.userLastName || "");
  const [phone, setphone] = useState(loggedUserData?.userPhoneNumber || "");

  const UpdateuserDetails = async () => {
    try {
      const res = await axios.post(EDIT_USER, {
        orgID: loggedUserData?.orgId,
        authKey: loggedUserData?.authKey,
        emailId: loggedUserData?.emailId,
        userFirstName: firstName,
        userLastName: lastName,
        password: loggedUserData?.password,
        title: loggedUserData?.title,
        linkToProfilePhoto: loggedUserData?.linkToProfilePhoto,
        userPhoneNumber: phone,
      });
      if (res.status === 200) {
        alert(res.data.message);
        localStorage.setItem(
          "userDetails",
          JSON.stringify({
            ...loggedUserData,
            userFirstName: firstName,
            userLastName: lastName,
            userPhoneNumber: phone,
          })
        );
        navigate("/user/userprofile");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  return (
    <div className="userprofile">
      <MainDashboardNavbar />
      <div className="userprofilebody">
        <div className="profileapp">
          <img src={yojn} alt="logo" />
        </div>
        <div className="profileimage">
          <p>User Profile</p>
          <img
            src={loggedUserData?.linkToProfilePhoto || user}
            alt="frofile"
            className="userprofileimage"
          />
        </div>
        <div className="profiledetails">
          <div className="userinformations">
            <div className="userfirstname">
              <h1>Email ID:</h1>
              <p>{loggedUserData?.emailId}</p>
            </div>
            <div className="userfirstname">
              <h1>First Name:</h1>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Enter First Name"
              />
            </div>
            <div className="userfirstname">
              <h1>Last Name:</h1>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Enter Last Name"
              />
              {/* <p>{loggedUserData?.userLastName}</p> */}
            </div>

            <div className="userfirstname">
              <h1>Phone No:</h1>
              <input
                type="text"
                value={phone}
                onChange={(e) => setphone(e.target.value)}
                placeholder="Enter Phone No"
              />
              {/* <p>{loggedUserData?.userPhoneNumber}</p> */}
            </div>
          </div>

          <div className="chageuserdetailsbuttons">
            <button onClick={() => navigate("/user/userprofile")}>Exit</button>
            <button onClick={() => UpdateuserDetails()}>Save</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserprofile;
