import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
// import MainDashboardNavbar from "../../components/subcomponents/MainDashboardNavbar";
import PMDashboardBody from "../../components/pmdashboardcomponents/PMDashboardBody";
import { useDispatch, useSelector } from "react-redux";
import { getAppList } from "../../Store/AppListSlice";
import ViewProjectDetail from "../../components/pmdashboardcomponents/ViewProjectDetail";
import PMDashboardNavbar from "./PMDashboardNavbar";

const PMDashboard = () => {
  const savedUserDetails = localStorage.getItem("userDetails");
  const userDetails = JSON.parse(savedUserDetails);
  const { appList } = useSelector((state) => state?.appList);
  const dispatch = useDispatch();
  const [viewProjectDetail, setViewProjectDetail] = useState(false);
  // const [isProjectCreated, setIsProjectCreated] = useState(false);

  // const [toggleApplist, setToggleApplist] = useState(false);
  const [activeProject, setActiveProject] = useState();
  const [activeApp, setActiveApp] = useState();
  useEffect(() => {
    const payload = { orgId: userDetails?.orgId };
    dispatch(getAppList({ payload, dispatch }));
  }, [dispatch, userDetails?.orgId]);
  useEffect(() => {
    if (appList) {
      setActiveApp(appList[0]);
    }
  }, [appList]);
  // console.log("activeprojecttttt", activeProject);
  // console.log("selectedApp", activeApp);

  return (
    <div className="maindashboard">
      <Sidebar />
      <div className="maindashboardmaincontent">
        <PMDashboardNavbar
          appList={appList}
          activeApp={activeApp}
          setActiveApp={setActiveApp}
          // setToggleApplist={setToggleApplist}
        />
        {viewProjectDetail ? (
          <ViewProjectDetail
            activeProject={activeProject}
            setViewProjectDetail={setViewProjectDetail}
            // toggleApplist={toggleApplist}
            // setToggleApplist={setToggleApplist}
          />
        ) : (
          <PMDashboardBody
            appList={appList}
            setViewProjectDetail={setViewProjectDetail}
            setActiveProject={setActiveProject}
            // toggleApplist={toggleApplist}
            // setToggleApplist={setToggleApplist}
            activeApp={activeApp}
            // setIsProjectCreated={setIsProjectCreated}
            activeProject={activeProject}
            setActiveApp={setActiveApp}
          />
        )}
      </div>
    </div>
  );
};

export default PMDashboard;
