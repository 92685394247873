import * as React from "react";
import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";
import { useState } from "react";
import { useEffect } from "react";

export default function SendFeedbackModal({
  show,
  setshow,
  onConfirm,
  userFeedbacks,
  vote,
  loggedUser,
}) {
  // console.log("Vote|||", vote);
  let { upVote, downVote } = userFeedbacks;
  // console.log("logged", loggedUser);
  const [begineerCount, setBegineerCount] = useState(0);
  const [intermediateCount, setIntermediateCount] = useState(0);
  const [advanceCount, setAdvanceCount] = useState(0);
  const [begineerApproved, setBegineerApproved] = useState(0);
  const [intermediateApproved, setIntermediateApproved] = useState(0);
  const [advanceApproved, setAdvanceApproved] = useState(0);
  const [weightageApprovalPercentage, setWeightApprovalPercentage] =
    useState(0);

  let newLoggedUser = {
    ...loggedUser,
    vote: vote,
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  let updatedUserFeedbacks = {
    ...userFeedbacks,
    comment: [...userFeedbacks.comment, newLoggedUser],
  };
  console.log("updatedUserFeedbacks", updatedUserFeedbacks);
  console.log("userFeedbacks", userFeedbacks);
  // console.log("weightageApprovalPercentage", weightageApprovalPercentage);
  // console.log("vote{}{}", vote);
  let beginnerValue = 20;
  let intermediateValue = 30;
  let advanceValue = 50;
  useEffect(() => {
    if (updatedUserFeedbacks) {
      // Check if the counts need to be updated
      let newBegineerCount = 0;
      let newIntermediateCount = 0;
      let newAdvanceCount = 0;
      let newBegineerApproved = 0;
      let newIntermediateApproved = 0;
      let newAdvanceApproved = 0;
      updatedUserFeedbacks?.comment.forEach((com) => {
        if (com.weightage === "Beginner") {
          newBegineerCount++;
          if (com.vote === 1) {
            newBegineerApproved++;
          }
        }
        if (com.weightage === "Intermediatery") {
          newIntermediateCount++;
          if (com.vote === 1) {
            newIntermediateApproved++;
          }
        }
        if (com.weightage === "Advanced") {
          newAdvanceCount++;
          if (com.vote === 1) {
            newAdvanceApproved++;
          }
        }
      });

      if (
        newBegineerCount !== begineerCount ||
        newIntermediateCount !== intermediateCount ||
        newAdvanceCount !== advanceCount
      ) {
        setBegineerCount(newBegineerCount);
        setIntermediateCount(newIntermediateCount);
        setAdvanceCount(newAdvanceCount);
      }
      if (
        newBegineerApproved !== begineerApproved ||
        newIntermediateApproved !== intermediateApproved ||
        newAdvanceApproved !== advanceApproved
      ) {
        setBegineerApproved(newBegineerApproved);
        setIntermediateApproved(newIntermediateApproved);
        setAdvanceApproved(newAdvanceApproved);
      }
    }
    // console.log("begineer", begineerCount);
    // console.log("intermediateCount", intermediateCount);
    // console.log("advanceCount", advanceCount);
    const userWeightage =
      begineerCount * beginnerValue +
      intermediateCount * intermediateValue +
      advanceCount * advanceValue;
    // console.log("userWeightage", userWeightage);
    const weightApproval =
      1 * begineerApproved * (beginnerValue / userWeightage) +
      1 * intermediateApproved * (intermediateValue / userWeightage) +
      1 * advanceApproved * (advanceValue / userWeightage);

    // console.log("weightApproval", weightApproval);
    setWeightApprovalPercentage((weightApproval * 100).toFixed(2));
  }, [
    userFeedbacks,
    begineerCount,
    intermediateCount,
    advanceCount,
    begineerApproved,
    intermediateApproved,
    advanceApproved,
    beginnerValue,
    intermediateValue,
    advanceValue,
    updatedUserFeedbacks,
  ]);
  // console.log("weightageApprovalPercentage", weightageApprovalPercentage);
  // console.log(
  //   "begineerCount,intermediateCount,advanceCount",
  //   begineerCount,
  //   intermediateCount,
  //   advanceCount,
  //   begineerApproved,
  //   intermediateApproved,
  //   advanceApproved
  // );
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={show}
      onClose={() => setshow(false)}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "md",
          pb: 0,
          boxShadow: "sm",
          background: "white",
        }}
      >
        <div style={{ border: "3px solid #256358", borderRadius: "7px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "10px 20px",
            }}
          >
            <p
              style={{
                fontWeight: "900",
                fontSize: "23px",
                marginLeft: "180px",
                marginRight: "auto",
              }}
            >
              Feedback Confirmation
            </p>
            <span
              style={{
                fontWeight: "bold",
                color: "black",
                fontSize: "25px",
                cursor: "pointer",
              }}
              onClick={() => setshow(false)}
            >
              X
            </span>
          </div>
          <div style={{ margin: "10px 20px" }}>
            <p
              style={{
                marginBottom: "0.3rem",
                color: "red",
                fontSize: "20px",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              You are about to submit the feedback!!!
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              background: "#ecf1f0",
              padding: "10px 15px",
            }}
          >
            <p
              style={{
                marginRight: "1rem",
                border: "1px solid #81b7b0",
                padding: "8px",
                borderRadius: "20px",
                color: "#81b7b0",
                fontWeight: "900",
                opacity: 1,
                background: "#ffffff",
              }}
            >
              Approval - {vote === 1 ? upVote + 1 : upVote}
            </p>
            <p
              style={{
                marginRight: "1rem",
                border: "1px solid red",
                padding: "8px",
                borderRadius: "20px",
                color: "red",
                fontWeight: "400",
                background: "#ffffff",
              }}
            >
              Disapproval - {vote === 0 ? downVote + 1 : downVote}
            </p>
            <div
              style={{
                marginRight: "2rem",
                border: "1px solid black",
                padding: "8px",
                borderRadius: "20px",
                color: "black",
                fontWeight: "600",
              }}
            >
              Weighted approval rating: {weightageApprovalPercentage}%
            </div>
            <button
              onClick={onConfirm}
              style={{
                padding: "10px 15px",
                backgroundColor: "#ace320",
                color: "black",
                fontWeight: "700",
                borderRadius: "5px",
                border: "1px solid #256358",
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </Sheet>
    </Modal>
  );
}
