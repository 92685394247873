import * as React from "react";
import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";
// import { useState } from "react";
// import { useEffect } from "react";

// import { ADD_PROJECT, EDIT_PROJECT } from "../../../util/ApiEndPoints";
export default function DeleteProjectModal({ show, setshow }) {
  //   const [showweightconfirm, setshowweightconfirm] = useState(false);

  // console.log("updatedUsersList>>", updatedUsersList);
  //   console.log('show',show)
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={show}
      onClose={() => setshow(false)}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "md",
          pb: 0,
          boxShadow: "sm",
          background: "white",
        }}
      >
        <div className="weightconfirmmodal">
          <div className="weightconfirmmodalheader"></div>
          <div className="weightconfirmmodalbody">
            Do you want to delete the project?
          </div>
          <div className="weightconfirmmodalfooter">
            <button onClick={() => setshow(false)}>Back</button>
            <button
              onClick={() => {
                // setshowweightconfirm(false);
                // handleStartTraining();
              }}
            >
              Delete Project
            </button>
          </div>
        </div>
      </Sheet>
    </Modal>
  );
}
