import React, { useState } from "react";
import "./UserProfile.css";
import MainDashboardNavbar from "../components/subcomponents/MainDashboardNavbar";
import yojn from "../Assets/yojn.svg";
import user from "../Assets/defaultuser.png";
import { useNavigate } from "react-router-dom";

import ChangePasswordModal from "../components/subcomponents/ChangePasswordModal";
const UserProfile = () => {
  const savedUserDetails = localStorage.getItem("userDetails");
  const loggedUserData = JSON.parse(savedUserDetails);
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="userprofile">
      <MainDashboardNavbar />
      <div className="userprofilebody">
        <div className="profileapp">
          <img src={yojn} alt="logo" />
        </div>
        <div className="profileimage">
          <h1>User Profile</h1>
          <img
            src={loggedUserData?.linkToProfilePhoto || user}
            alt="frofile"
            className="userprofileimage"
          />
        </div>
        <div className="profiledetails">
          <div className="userinformations">
            <div className="userfirstname">
              <h1>First Name:</h1>
              <p>{loggedUserData?.userFirstName}</p>
            </div>
            <div className="userfirstname">
              <h1>Last Name:</h1>
              <p>{loggedUserData?.userLastName}</p>
            </div>
            <div className="userfirstname">
              <h1>Email ID:</h1>
              <p>{loggedUserData?.emailId}</p>
            </div>
            <div className="userfirstname">
              <h1>Phone No:</h1>
              <p>{loggedUserData?.userPhoneNumber}</p>
            </div>
          </div>

          <div className="chageuserdetailsbuttons">
            <button
              className="profilebackbutton"
              onClick={() => navigate("/user/home")}
            >
              Back
            </button>
            <button onClick={() => setShowPasswordPopup(true)}>
              Change Password
            </button>
            <button onClick={() => navigate("/user/editprofile")}>
              Edit Profile
            </button>
          </div>
        </div>
      </div>
      {showPasswordPopup && (
        <ChangePasswordModal
          show={showPasswordPopup}
          setshow={setShowPasswordPopup}
          loggedUserData={loggedUserData}
        />
      )}
    </div>
  );
};

export default UserProfile;
