import React, { useEffect, useState } from "react";
import "./Main.css";
import Navbar from "../components/subcomponents/Navbar";
import Body from "../components/subcomponents/Body";
import { useDispatch, useSelector } from "react-redux";
import { getAppList } from "../Store/AppListSlice";
// import { getConfigDetails } from "../Store/ConfigSlice";
// import _ from "lodash";
import { filterByAppId } from "./genricComponent/commonOperations";
// import { getAllProjects } from "../Store/AllProjectsSlice";
const Main = () => {
  const dispatch = useDispatch();
  const { appList } = useSelector((state) => state?.appList);
  const { traces } = useSelector((state) => state?.traces);
  // console.log("traces", traces);

  const [applist, setApplist] = useState([]);
  // console.log("APPLIST||", appList);
  const [traceData, setTracesData] = useState([]);
  // console.log("traceData||", traceData);

  const savedUserDetails = localStorage.getItem("userDetails");
  const userDetails = JSON.parse(savedUserDetails);

  useEffect(() => {
    const payload = { orgId: userDetails?.orgId };
    dispatch(getAppList({ payload, dispatch }));
  }, [dispatch, userDetails?.orgId]);
  useEffect(() => {
    if (appList) {
      setApplist(appList);
    }
  }, [appList]);
  const filterApp = (app) => {
    const traceAr = filterByAppId(traces, app?.appName);
    setTracesData(traceAr);
  };
  return (
    <div className="main">
      <Navbar
        appList={applist}
        setApplist={setApplist}
        filterByApp={filterApp}
      />
      <Body
        activeApp={applist?.find((app) => app?.isSelected) || applist[0]}
        setTracesData={setTracesData}
        traceData={traceData}
        traces={traces}
      />
    </div>
  );
};

export default Main;
