import { createSlice } from "@reduxjs/toolkit";
// import { start } from "repl";
const LoggedUserSlice = createSlice({
  name: "loggedUser",
  initialState: {
    userData: {},
    userProjects: null,
  },
  reducers: {
    getLoggedUserDetails: (state, { payload }) => {
      // console.log("payload", payload);
      state.userData = payload;
    },
    getuserProject: (state, { payload }) => {
      state.userProjects = payload;
    },
  },
});
export const { getLoggedUserDetails, getuserProject } = LoggedUserSlice.actions;
export default LoggedUserSlice.reducer;
