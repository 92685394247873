import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import "./Body.css";
import { IoSearchOutline } from "react-icons/io5";
import { PiArrowsDownUpLight } from "react-icons/pi";
import { FaCalendarDays } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTraces } from "../../Store/TracesSlice";
import Checkbox from "../genricComponent/Checkbox";
import {
  handleSelectTrash,
  selectAllTrashes,
  selectedTracesByNodes,
  selectedTracesByNodesHtoL,
  sortByDateLatestToOldest,
  sortByDateOldestToLatest,
} from "../genricComponent/commonOperations";
import _ from "lodash";
import BasicModal from "./BasicModal";
import SaveTrashModalContent from "../genricComponent/SaveTrashModalContent";
import { ADD_TRACES, GET_TRACES } from "../../util/ApiEndPoints";
import axios from "axios";

const Body = ({ activeApp, setTracesData, traceData, traces }) => {
  const dispatch = useDispatch();
  const [showTrashModal, setShowTrashModal] = useState(false);
  const list = useSelector((state) => state?.projectsList?.baseProjectsList);
  // console.log("ListProjects??", list);
  const navigate = useNavigate();
  // console.log("activeApp|}}}}", activeApp);
  // console.log("ttraceData|}}}}", traceData);
  const [showSortby, setShowSortby] = useState(false);
  const loading = useSelector((state) => state?.traces.status === "loading");
  const [showCalender, setShowCalender] = useState(false);
  const [projectsList, setProjectList] = useState([]);

  const [searcText, setSearchText] = useState(null);
  // const { loggedUserData } = useSelector((state) => state?.loggedUserData);
  // const isDisabled = loggedUserData?.title === "SuperAdmin" ? false : true;
  const savedUserDetails = localStorage.getItem("userDetails");
  const userDetails = JSON.parse(savedUserDetails);
  // useEffect(() => {
  //   const savedUserDetails = localStorage.getItem("userDetails");
  //   if (!savedUserDetails) {
  //     navigate("/");
  //   }
  // }, [navigate]);

  const filterData = _.filter(traceData, (tr) =>
    !_.isNull(searcText)
      ? tr.input
          ?.toLowerCase()
          .substr(0, searcText.length)
          .match(searcText.toLowerCase())
      : traceData
  );
  // console.log("filteredData", filterData);
  const [date1, setDate1] = useState({
    d: null,
    m: null,
    y: null,
  });
  const [date2, setDate2] = useState({
    d: null,
    m: null,
    y: null,
  });

  const clearDateFilter = () => {
    const empDate = {
      d: null,
      m: null,
      y: null,
    };
    setDate1(empDate);
    setDate2(empDate);
  };

  useEffect(() => {
    // console.log("orgid|}|}", userDetails.orgId);
    dispatch(getTraces(GET_TRACES + userDetails?.orgId));
  }, [dispatch, userDetails?.orgId]);

  const getAllTraces = useCallback(() => {
    if (!_.isEmpty(traces)) {
      const newfilteredTrashes = _.filter(
        traces,
        (item) => _.isEmpty(item.parentRunId) && item.name === "AgentExecutor"
      );

      setTracesData(newfilteredTrashes);
    }
  }, [setTracesData, traces]);
  useEffect(() => {
    getAllTraces();
  }, [getAllTraces]);
  useEffect(() => {
    if (list) {
      setProjectList(list);
    }
  }, [getAllTraces, list]);
  // const disableAlltraces = () => {
  //   const disabledTraces = _.map(traceData, (tr) => {
  //     return {
  //       ...tr,
  //       isSelected: false,
  //     };
  //   });
  //   setTracesData(disabledTraces);
  // };

  const handleSaveTrash = async () => {
    const customHeaders = {
      Authorization: `Bearer ${userDetails?.access_token}`,
      // Add more custom headers if needed
    };
    const selectedTraceIdList = [];
    _.forEach(traceData, (item) => {
      if (item.isSelected) {
        selectedTraceIdList.push(_.toString(item.id));
      }
    });

    const selectedProjectIdList = [];
    _.forEach(projectsList, (item) => {
      if (item.isSelected) {
        selectedProjectIdList.push(item.projectId);
      }
    });

    const payload = [];
    _.forEach(selectedProjectIdList, (projectId) => {
      payload.push({
        // appId: activeApp?.app_id,
        projectId: projectId,
        // clientId: orgId,
        traceId: selectedTraceIdList,
        orgId: userDetails?.orgId,
      });
    });
    try {
      const { data } = await axios.post(ADD_TRACES, payload, {
        headers: customHeaders,
      });
      // console.log("response::", data);
      setShowTrashModal(false);
      alert(data?.message);
      selectAllTraces();
      return data;
    } catch (error) {
      console.log("Error", error);
    }
  };

  const selectProject = (pid) => {
    const newProjectList = _.map(projectsList, (item, index) =>
      item.projectId === pid ? { ...item, isSelected: !item.isSelected } : item
    );
    // console.log("newProjectList", newProjectList);
    setProjectList(newProjectList);
  };

  const handleDate1 = (t, val) => {
    let newDate;
    if (t === "d") {
      newDate = {
        ...date1,
        d: val,
      };
    }
    if (t === "m") {
      newDate = {
        ...date1,
        m: val,
      };
    }
    if (t === "y") {
      newDate = {
        ...date1,
        y: val,
      };
    }
    setDate1(newDate);
  };

  const handleDate2 = (t, val) => {
    let newDate;
    if (t === "d") {
      newDate = {
        ...date2,
        d: val,
      };
    }
    if (t === "m") {
      newDate = {
        ...date2,
        m: val,
      };
    }
    if (t === "y") {
      newDate = {
        ...date2,
        y: val,
      };
    }
    setDate2(newDate);
  };

  const handlFilterByDate = () => {
    const newTraceData = _.filter(traceData, (trace) => {
      const newTrace = trace.date.split("|")[0];
      // console.log("newTrace", newTrace);
      const traceTimeStamp = new Date(newTrace).getTime();
      // console.log("traceTimeStamp", traceTimeStamp);
      // Construct date strings in "YYYY-MM-DD" format
      const filterDateTo = `${date1["y"]}-${date1["m"]}-${date1["d"]}`;
      const filterDateFrom = `${date2["y"]}-${date2["m"]}-${date2["d"]}`;
      // console.log("filterDateTo,filterDateFrom", filterDateTo, filterDateFrom);
      // Convert date strings to timestamps
      const filterTimeStampTo = Number(new Date(filterDateTo).getTime());
      const filterTimeStampFrom = Number(new Date(filterDateFrom).getTime());
      // console.log("filterTimeStampTo", filterTimeStampTo);
      // console.log("filterTimeStampFrom", filterTimeStampFrom);

      if (
        !isNaN(filterTimeStampTo) &&
        !isNaN(filterTimeStampFrom) &&
        traceTimeStamp >= filterTimeStampFrom &&
        traceTimeStamp <= filterTimeStampTo
      ) {
        // console.log("TRACE", trace);
        return { ...trace }; // Include this trace in the filtered result
      }
    });

    setTracesData(newTraceData);
    setShowCalender(false);
  };

  const isAnyTraceSelected = useMemo(() => {
    return _.every(traceData, (item) => item.isSelected);
  }, [traceData]);
  const selectAllTraces = useCallback(() => {
    return selectAllTrashes(traceData, setTracesData);
  }, [setTracesData, traceData]);
  const handletraceinput = (input) => {
    // console.log(typeof input);
    if (typeof input === "string") {
      return input;
    } else {
      return "Trace Does not exist";
    }
  };

  // console.log("tracesss", traceData);

  const restoreDefault = () => {
    getAllTraces();
    clearDateFilter();
    setShowSortby(false);
  };

  return (
    <div className="body">
      <div className="mainbody">
        <div className="bodysetting">
          <div className="settings">
            <div className="searchkeyword">
              <IoSearchOutline />
              <input
                type="text"
                placeholder="Search by keyword"
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
            <div className="filters">
              <PiArrowsDownUpLight onClick={() => setShowSortby(!showSortby)} />
              {showSortby && (
                <div className="sortby">
                  <div className="upperpart">
                    <p>Sort by</p>
                  </div>
                  <div className="line"></div>
                  <div className="lowerpart">
                    <p onClick={restoreDefault}>Default</p>
                    <p
                      onClick={() => {
                        // console.log(
                        //   "data::",
                        //   sortByDateLatestToOldest(traceData)
                        // );
                        setTracesData(sortByDateLatestToOldest(traceData));
                        setShowSortby(false);
                      }}
                    >
                      Date : Latest to Oldest
                    </p>
                    <p
                      onClick={() => {
                        setTracesData(sortByDateOldestToLatest(traceData));
                        setShowSortby(false);
                      }}
                    >
                      Date : Oldest to Latest
                    </p>
                    <p
                      onClick={() =>
                        selectedTracesByNodes(
                          traceData,
                          setTracesData,
                          traces,
                          setShowSortby
                        )
                      }
                    >
                      No. of Nodes : Lowest to Highest
                    </p>
                    <p
                      onClick={() =>
                        selectedTracesByNodesHtoL(
                          traceData,
                          setTracesData,
                          traces,
                          setShowSortby
                        )
                      }
                    >
                      No. of Nodes : Highest to Lowest
                    </p>
                  </div>
                </div>
              )}
              <FaCalendarDays onClick={() => setShowCalender(!showCalender)} />
              {showCalender && (
                <div className="calender">
                  <div className="calenderupperpart">
                    <h5>Select Date Range</h5>
                    <div onClick={() => setShowCalender(!showCalender)}>X</div>
                  </div>
                  <div className="calenderfilterdate">
                    <div className="calenderstartdate">
                      <h5 className="dateto">From: </h5>
                      <input
                        type="text"
                        placeholder="DD"
                        value={date2?.d}
                        onChange={(e) => handleDate2("d", e.target.value)}
                      />
                      <h5>/</h5>
                      <input
                        type="text"
                        placeholder="MM"
                        value={date2?.m}
                        onChange={(e) => handleDate2("m", e.target.value)}
                      />
                      <h5>/</h5>
                      {/* <div className="calenderstartdate"> */}
                      <input
                        type="text"
                        placeholder="YYYY"
                        value={date2?.y}
                        onChange={(e) => handleDate2("y", e.target.value)}
                      />
                      {/* </div> */}
                    </div>
                    <div className="calenderstartdate">
                      <h5 className="dateto">To: </h5>
                      <input
                        type="text"
                        placeholder="DD"
                        value={date1?.d}
                        onChange={(e) => handleDate1("d", e.target.value)}
                      />
                      <h5>/</h5>
                      <input
                        type="text"
                        placeholder="MM"
                        value={date1?.m}
                        onChange={(e) => handleDate1("m", e.target.value)}
                      />
                      <h5>/</h5>
                      {/* <div className="calenderstartdate1"> */}
                      <input
                        type="text"
                        placeholder="YYYY"
                        value={date1?.y}
                        onChange={(e) => handleDate1("y", e.target.value)}
                      />
                      {/* </div> */}
                    </div>
                  </div>
                  <div className="savecalederdate">
                    <button onClick={handlFilterByDate}>Save</button>
                    <button onClick={clearDateFilter}>clear</button>
                  </div>
                </div>
              )}
              <button onClick={restoreDefault}>Restore Default</button>
              <button onClick={() => window.location.reload()}>Refresh</button>
            </div>
          </div>
          <div className="filterbuttons">
            <Checkbox
              onSelect={selectAllTraces}
              isSelected={isAnyTraceSelected}
              style={{
                width: "20px",
                height: "20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              title={"Select all"}
              count={_.size(_.filter(traceData, (item) => item?.isSelected))}
            />
            <button
              onClick={() => setShowTrashModal(true)}
              id="saveTrash"
              disabled={
                _.some(traceData, (item) => item.isSelected) ? false : true
              }
            >
              Save To Project
            </button>
          </div>
        </div>
        <div className="bodycontent">
          {loading ? (
            // <div className="trashContainerCard">
            <div className="loadindata">LOADING...</div>
          ) : (
            // </div>
            traceData &&
            filterData
              ?.slice()
              .reverse()
              .map((trace) => (
                <div className="trashContainerCard" key={trace.id}>
                  {/* {console.log("traceee", trace)} */}

                  {/* {console.log("isSelected", trace.isSelected)} */}
                  <Checkbox
                    onSelect={() => {
                      handleSelectTrash(setTracesData, traceData, trace.id);
                      // console.log("handleSelectTrash", traceData);
                      setProjectList(list);
                    }}
                    isSelected={trace?.isSelected}
                    style={{
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                      // display: `${isDisabled ? "none" : ""}`,
                    }}
                  />
                  <div
                    onClick={() =>
                      navigate("/user/card", {
                        state: {
                          trace: trace,
                          data: _.filter(
                            traces,
                            (tr) => tr.conversationId === trace.conversationId
                          ),
                          activeApp: activeApp,
                        },
                      })
                    }
                    className="cards"
                  >
                    <div className="carddetails">
                      <div className="userid">#{trace.id}</div>
                      {/* {console.log("input", trace.input, trace.id)} */}
                      <div className="userquery">
                        {handletraceinput(trace.input)}
                      </div>
                    </div>
                    <div className="carddetails">
                      <div
                        className="userquery"
                        style={{
                          marginLeft: "20px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {/* <p style={{ fontSize: "13px", fontWeight: "bold" }}>
                          UserId-
                        </p> */}
                        {trace.userId}
                      </div>
                      <div className="usernumber">
                        {
                          _.filter(
                            traces,
                            (tr) => tr.parentRunId === trace.runId
                          ).length
                        }
                      </div>
                      <div>{trace.date}</div>
                    </div>
                  </div>
                </div>
              ))
          )}
        </div>
      </div>

      {showTrashModal && (
        <BasicModal
          show={showTrashModal}
          setshow={setShowTrashModal}
          Component={SaveTrashModalContent}
          handleSaveTrash={handleSaveTrash}
          selectProject={selectProject}
          projectsList={projectsList}
        />
      )}
    </div>
  );
};

export default memo(Body);
