import React, { useEffect, useState } from "react";
import "./LoginUser.css";
// import yojn from "../Assets/yojnLogo.png";
import yojnai from "../Assets/Group 8644.png";
// import IBGPT from "../ChatGPT_Clone_assets/assets/IBGPT.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LOGIN } from "../util/ApiEndPoints";
import axios from "axios";
import { addLoggedUserDetails } from "../Store/LoggedUserDetailsSlice";
const LoginUser = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const [renderText, setRenderText] = useState("");
  const [displayWords, setDisplayedWords] = useState([]);
  const savedUserDetails = localStorage.getItem("userDetails");
  const userDetails = JSON.parse(savedUserDetails);
  useEffect(() => {
    const chat = [
      {
        text: "No No VooDoo Economics! ",
      },
      {
        text: "You’ll You’ll get clear visibility of your cloud spend as well! ",
      },
      {
        text: "Take Take your GenAI demo to production faster! ",
      },
    ];
    const displayNextChat = (index) => {
      const nextIndex = (index + 1) % chat.length;
      setDisplayedWords([]);
      setRenderText(chat[nextIndex].text);
      // setRenderHeading(chat[nextIndex].h);
      setTimeout(() => {
        displayNextChat(nextIndex);
      }, 4000);
    };
    displayNextChat(-1);
  }, []);
  useEffect(() => {
    if (renderText.length > 0) {
      const words = renderText.split(" ");
      // console.log("words", renderHeading, ">>", words, "length", words.length);
      let index = 0;
      const intervalId = setInterval(() => {
        if (index < words.length) {
          // console.log("index||", index);
          // console.log("wordsindex", words[index]);
          setDisplayedWords((prevWords) => [...prevWords, " ", words[index]]);
          index += 1;
        } else {
          clearInterval(intervalId);
        }
      }, 300); // Adjust the delay between words

      return () => clearInterval(intervalId);
    }
  }, [renderText]);
  const handleLogin = async () => {
    if (email === "" && password === "") {
      alert("fill all details");
    }
    try {
      const res = await axios.post(LOGIN, {
        email,
        password,
      });
      // console.log("res||", res);
      if (res.data && res.status === 200) {
        // setUserID(email);
        dispatch(addLoggedUserDetails(res.data));
        localStorage.setItem(
          "userDetails",
          JSON.stringify({
            ...res.data,
            isDisable:
              res?.data?.title.toLowerCase() === "superadmin" ||
              res?.data?.title.toLowerCase() === "admin"
                ? false
                : true,
          })
        );
        navigate("/user/home");
      } else {
        alert("Login failed. Please check your email and password.");
      }
    } catch (error) {
      console.log("error", error.message);
      alert("An error occurred. Please try again.");
    }
  };
  // const savedUserDetails = localStorage.getItem("userDetails");
  // const userDetails = JSON.parse(savedUserDetails);
  // const { Component } = props;
  useEffect(() => {
    if (userDetails) {
      navigate("/user");
    }
  });
  const handleEnter = async (e) => {
    if (e.key === "Enter") await handleLogin();
  };
  return (
    <div className="loginPage">
      <div className="leftSide">
        <div className="upperLeft">
          <div className="upperLeftchild">
            {/* <p>YOJN</p> */}
            <div className="yojnlogoandname">
              <img src={yojnai} alt="logo" className="yojnlogo" />
              <h1>YOJN AI</h1>
            </div>
          </div>
        </div>
        <div className="middleleft">
          <div className="randomText">
            <h1>Self Hosted Reliable AI</h1>
            <h2 className="fristtext">{displayWords}</h2>
          </div>
        </div>
        <div className="bottomleft">
          <a
            href="https://www.daostudio.in/pricing"
            className="signupyojnbutton"
          >
            Signup to Yojn
          </a>
          <ul className="yojnqualitylist">
            <li>Get Unlimited Users</li>
            <li>Get Unlimited Traces</li>
            <li>Get Unlimited Agents</li>
            <li>Get Unlimited Adapters</li>
          </ul>
        </div>
      </div>
      <div className="right">
        <div className="rightupperdetails">
          <h2>Get Started</h2>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email Address"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleEnter}
            placeholder="Enter Password"
          />
          <button className="logingmainbutton" onClick={handleLogin}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginUser;
