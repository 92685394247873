import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
// import { getAllProjects } from "./AllProjectsSlice";
// import { getUsers } from "./UserSlice";
import { CREATE_APP, GET_APPS } from "../util/ApiEndPoints";
import { getAllBaseProjects } from "./AllProjectsSlice";
import { getUsers } from "./UserSlice";

const savedUserDetails = localStorage.getItem("userDetails");
const userDetails = JSON.parse(savedUserDetails);
export const getAppList = createAsyncThunk("getAppList", async (sample) => {
  // console.log("payload||", sample);
  const { payload, dispatch } = sample;
  const { orgId } = payload;
  // console.log("orgId<><>", orgId);

  try {
    const res = await axios.get(`${GET_APPS}${orgId}`);
    // console.log("appid::", payload, res.data);
    if (res.status === 200) {
      await dispatch(
        createAppfun({
          appId: res.data[0]?.app_id,
          orgId: orgId,
          baseAppId: "",
        })
      );
      await dispatch(
        getAllBaseProjects({
          orgId: orgId,
          appId: res.data[0]?.app_id,
        })
      );
      await dispatch(
        getUsers({
          appId: res.data[0]?.app_id,
          orgId: userDetails?.orgId,
        })
      );
      return res.data;
    }
  } catch (error) {
    throw error;
  }
});

export const createAppfun = createAsyncThunk(
  "createAppfun",
  async (payload) => {
    const savedUserDetails = localStorage.getItem("userDetails");
    const userDetails = JSON.parse(savedUserDetails);
    const customHeaders = {
      Authorization: `Bearer ${userDetails?.access_token}`,
    };
    // console.log("createappPayload", payload);
    try {
      const res = await axios.post(CREATE_APP, payload, {
        headers: customHeaders,
      });
      if (res.status === 200) {
      }
    } catch (error) {
      console.log("Error", error);
    }
  }
);

const appList = createSlice({
  name: "appList",
  initialState: {
    appList: null,
    status: "idle",
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAppList.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getAppList.fulfilled, (state, { payload }) => {
        state.status = "succeeded";
        state.appList = payload;
      })
      .addCase(getAppList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default appList.reducer;
