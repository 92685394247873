import React, { useState } from "react";
import "./Navbar.css";
import { IoMdArrowDropdown } from "react-icons/io";
// import { useSelect } from "@mui/base";
// import { useDispatch, useSelector } from "react-redux";
// import { getAppList } from "../../Store/AppListSlice";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { getAllBaseProjects } from "../../Store/AllProjectsSlice";

const Navbar = ({ appList, setApplist, filterByApp }) => {
  const savedUserDetails = localStorage.getItem("userDetails");
  const userDetails = JSON.parse(savedUserDetails);
  const [showApps, setShowApps] = useState(false);
  // console.log("applist", appList);
  const dispatch = useDispatch();

  const selectApp = (recapp) => {
    console.log("recapp", recapp);
    const newlist = appList?.map((app) => {
      if (app.app_id === recapp.app_id) {
        return {
          ...app,
          isSelected: true,
        };
      } else {
        return {
          ...app,
          isSelected: false,
        };
      }
    });
    dispatch(
      getAllBaseProjects({
        orgId: userDetails?.orgId,
        appId: recapp?.app_id,
      })
    );
    filterByApp(recapp);
    setApplist(newlist);
    setShowApps(false);
  };

  return (
    <div className="navbar">
      <div className="navbarchild">
        <div className="selectappslist" onClick={() => setShowApps(!showApps)}>
          {_.find(appList, (app) => app?.isSelected)?.appName || "APPS"}
          <IoMdArrowDropdown />
        </div>
        {showApps && (
          <div className="apptoselectlist">
            {appList?.map((app, index) => (
              <div key={index} onClick={() => selectApp(app)}>
                {app.appName}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
