import { createSlice } from "@reduxjs/toolkit";
const userConditionsSlice = createSlice({
  name: "userConditions",
  initialState: {
    isuserAccess: false,
    iscreateProject: false,
    // isActive: false,
    isSelectedApp: false,
  },
  reducers: {
    changeUserAccess: (state, { payload }) => {
      state.isuserAccess = payload;
    },
    allowCreateProject: (state, { payload }) => {
      state.iscreateProject = payload;
    },
    // handleIsActive: (state, { payload }) => {
    //   state.isActive = payload;
    // },
    changeSelectedApp: (state, { payload }) => {
      state.isSelectedApp = payload;
    },
  },
});
export const {
  changeUserAccess,
  allowCreateProject,
  // handleIsActive,
  changeSelectedApp,
} = userConditionsSlice.actions;
export default userConditionsSlice.reducer;
