import React from "react";
import "./AppDetailPmdashboard.css";
import ibgpt from "../../../Assets/ibgptlogo.svg";
const AppDetailPmdashboard = () => {
  return (
    <div className="appdetailpmdash">
      <div style={{ height: "10%" }}>
        <h1>Current App Version Details</h1>
      </div>
      <div className="applogodescriptionappdetails">
        <div className="applogopmdash">
      
         
        </div>
        <div className="appdetaildescription">
          <h2>Description</h2>
        </div>
      </div>
      <div className="appprojectversiondetails">
        <div className="appprojectversiondetailschild">
          <h1>Current Project Version Details</h1>
          <h2>Versioning Type:</h2>
          <h2>Current project version: </h2>
          <h2>Updation in Weights: </h2>
          <h2>Base version of the app: </h2>
          <h2>Incorporation of Dataset: </h2>
        </div>
        <div className="appprojectversiondetailschild">
          <h1>Current Parameters of the App</h1>
          <h2>Metric 1 :</h2>
          <h2>Metric 2 :</h2>
          <h2>Metric 3 :</h2>
          <h2>Metric 4 :</h2>
          <h2>Metric 5 :</h2>
        </div>
        {/* <div></div> */}
      </div>
    </div>
  );
};

export default AppDetailPmdashboard;
