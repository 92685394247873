import React, { useEffect, useState } from "react";
import "./RightCardProperty.css";
// import BasicModal from "./BasicModal";
// import FeedBackConfirmationContent from "./FeedBackConfirmationContent";
import _ from "lodash";
import UserCard from "../genricComponent/userCard";
import { useSelector } from "react-redux";
// import { getUsersFeedbacks } from "../../Store/UserFeedbackSlice";
import SendFeedbackModal from "./SendFeedbackModal";

const RightCardProperty = ({
  comment,
  setComment,
  clickedTrace,
  // userFeedbacks,
  sendFeedback,
  activeApp,
  state,
}) => {
  console.log("clickedTrace::", clickedTrace);
  // console.log("Commet||", comment);
  const { userFeedbacks } = useSelector((state) => state?.userFeedbacks);
  // console.log("userFeedbacks:", userFeedbacks);
  let newUserFeedbacks = {
    ...userFeedbacks,
  };
  // console.log("newUserFeedbacks", newUserFeedbacks);
  const [show, setshow] = useState(false);
  const [appuserFeedback, setAppuserFeedback] = useState("");
  const [isApproved, setIsApproved] = useState("Approve");
  const savedUserDetails = localStorage.getItem("userDetails");
  const userDetails = JSON.parse(savedUserDetails);
  const vote = isApproved === "Approve" ? 1 : 0;
  const [begineerCount, setBegineerCount] = useState(0);
  const [intermediateCount, setIntermediateCount] = useState(0);
  const [advanceCount, setAdvanceCount] = useState(0);
  const [begineerApproved, setBegineerApproved] = useState(0);
  const [intermediateApproved, setIntermediateApproved] = useState(0);
  const [advanceApproved, setAdvanceApproved] = useState(0);
  const [weightageApprovalPercentage, setWeightApprovalPercentage] =
    useState(0);
  const [loggedUser, setLoggedUser] = useState("");
  let beginnerValue = 20;
  let intermediateValue = 30;
  let advanceValue = 50;

  const { baseProjectsList } =
    useSelector((state) => state?.projectsList) || [];
  // console.log("projectsList", baseProjectsList);
  useEffect(() => {
    if (state) {
      if (state?.data[state?.data.length - 1]?.userFeedback === "1") {
        setAppuserFeedback("Approved");
      } else if (state?.data[state?.data.length - 1]?.userFeedback === "0") {
        setAppuserFeedback("Disapproved");
      } else {
        setAppuserFeedback("");
      }
    }
  }, [state]);
  useEffect(() => {
    if (baseProjectsList) {
      for (const project of baseProjectsList) {
        const user = project.users.find(
          (usr) => usr?.emailId === userDetails?.emailId
        );
        if (user) {
          setLoggedUser(user);
          break;
        }
      }
    }
  }, [baseProjectsList, userDetails.emailId]);

  // console.log("logged", loggedUser);

  useEffect(() => {
    if (userFeedbacks) {
      // Check if the counts need to be updated
      let newBegineerCount = 0;
      let newIntermediateCount = 0;
      let newAdvanceCount = 0;
      let newBegineerApproved = 0;
      let newIntermediateApproved = 0;
      let newAdvanceApproved = 0;
      userFeedbacks?.comment.forEach((com) => {
        if (com.weightage === "Beginner") {
          newBegineerCount++;
          if (com.vote === 1) {
            newBegineerApproved++;
          }
        }
        if (com.weightage === "Intermediatery") {
          newIntermediateCount++;
          if (com.vote === 1) {
            newIntermediateApproved++;
          }
        }
        if (com.weightage === "Advanced") {
          newAdvanceCount++;
          if (com.vote === 1) {
            newAdvanceApproved++;
          }
        }
      });

      if (
        newBegineerCount !== begineerCount ||
        newIntermediateCount !== intermediateCount ||
        newAdvanceCount !== advanceCount
      ) {
        setBegineerCount(newBegineerCount);
        setIntermediateCount(newIntermediateCount);
        setAdvanceCount(newAdvanceCount);
      }
      if (
        newBegineerApproved !== begineerApproved ||
        newIntermediateApproved !== intermediateApproved ||
        newAdvanceApproved !== advanceApproved
      ) {
        setBegineerApproved(newBegineerApproved);
        setIntermediateApproved(newIntermediateApproved);
        setAdvanceApproved(newAdvanceApproved);
      }
    }
    // console.log("begineer", begineerCount);
    // console.log("intermediateCount", intermediateCount);
    // console.log("advanceCount", advanceCount);
    const userWeightage =
      begineerCount * beginnerValue +
      intermediateCount * intermediateValue +
      advanceCount * advanceValue;
    // console.log("userWeightage", userWeightage);
    const weightApproval =
      1 * begineerApproved * (beginnerValue / userWeightage) +
      1 * intermediateApproved * (intermediateValue / userWeightage) +
      1 * advanceApproved * (advanceValue / userWeightage);

    // console.log("weightApproval", weightApproval);
    setWeightApprovalPercentage((weightApproval * 100).toFixed(2));
  }, [
    userFeedbacks,
    begineerCount,
    intermediateCount,
    advanceCount,
    begineerApproved,
    intermediateApproved,
    advanceApproved,
    beginnerValue,
    intermediateValue,
    advanceValue,
  ]);
  // console.log("weightageApprovalPercentage", weightageApprovalPercentage);
  // console.log(
  //   "begineerCount,intermediateCount,advanceCount",
  //   begineerCount,
  //   intermediateCount,
  //   advanceCount,
  //   begineerApproved,
  //   intermediateApproved,
  //   advanceApproved
  // );
  const onConfirm = () => {
    const feed = {
      appId: activeApp?.app_id,
      userEmail: userDetails?.emailId,
      comment: comment,
      vote: vote,
      traceId: clickedTrace?.id,
      orgId: userDetails?.orgId,
    };
    sendFeedback(feed);
    setshow(false);
  };
  // useEffect(() => {
  //   if (state) {
  //     dispatch(
  //       getUsersFeedbacks({
  //         traceId: clickedTrace?.id,
  //         appId: activeApp?.app_id,
  //       })
  //     );
  //   }
  // }, [activeApp?.app_id, clickedTrace?.id, dispatch, state]);
  // const handletraceinput = (trace) => {
  //   console.log(
  //     "inputttt",
  //     trace.input,
  //     "id",
  //     trace.id,
  //     "type",
  //     typeof trace.input
  //   );
  //   if (typeof trace?.input === "string") {
  //     return trace?.input;
  //   } else if (trace?.input === null) {
  //     return "";
  //   } else {
  //     return trace?.input[0]?.text;
  //   }
  // };

  return (
    <div className="rightCardproperty">
      <div className="rightcardNavbar">
        <div className="smallcircle"></div>
      </div>
      <div className="rightcardbody">
        <div className="rightcardbodyheader">
          <div className="rightcardbodyheaderfirstchild">
            <h4>#{clickedTrace?.id}</h4>
            {/* <p className="controlsubtraceinput"></p> */}
          </div>
          <div className="rightcardbodyheadersecondchild">
            <p className="lcr">{clickedTrace?.byFunc}</p>
            <p>{clickedTrace?.date}</p>
          </div>
        </div>
        <div className="rightcardbodymain">
          <div className="approveornotstat">
            <p>Approved</p>
            <div className="approvenumber">{userFeedbacks?.upVote || 0}</div>
            <p>disapproved</p>
            <div className="dissapprovednumber">
              {userFeedbacks?.downVote || 0}
            </div>
            <p>
              Weighted Approvel Rate:{" "}
              {weightageApprovalPercentage > 0
                ? weightageApprovalPercentage
                : 0}
              %
            </p>
          </div>
          <div className="rightcardbodycontent">
            {_.map(userFeedbacks?.comment, (user, index) => (
              <UserCard usr={user} key={index} />
            ))}
            <div className="rightcardbodycontentchild">
              <div>
                <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
                  User Feedback For {state?.data[state?.data?.length - 1].app}
                </h2>
              </div>
              <div className="userproperties feedbackuser">
                <p>{state?.data?.[state.data.length - 1].userId}</p>
                <p
                  className={`detailsapproved ${
                    appuserFeedback === "Disapproved" && "detailsdisapproved"
                  }`}
                >
                  {appuserFeedback}
                </p>
                <p>{state?.data?.[state.data.length - 1].date}</p>
              </div>
            </div>
          </div>
          {/* <div className="rightcardbodyfooter">
            <p style={{ marginLeft: 30 }}>
              Summarized comment:{clickedTrace?.operatorDescriptiveFeedback}
            </p>
          </div> */}
        </div>
      </div>
      <div className="rightcardfooter">
        <div className="cardfooterfirstchild">
          <button
            style={{ color: isApproved !== "Approve" && "black" }}
            onClick={() => setIsApproved("Approve")}
            className={`${isApproved === "Approve" && "approvebutton"}`}
          >
            Approve
          </button>
          <button
            style={{ color: isApproved !== "DisApproved" && "black" }}
            onClick={() => setIsApproved("DisApproved")}
            className={`${isApproved === "DisApproved" && "approvebutton"}`}
          >
            DisApprove
          </button>
        </div>
        <div className="cardfootersecondchild">
          <input
            type="text"
            style={{ padding: 8 }}
            value={comment}
            placeholder="type your feedback here...."
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
        <div className="cardfooterthirdchild">
          <button
            style={{
              backgroundColor: !_.isEmpty(isApproved) && "#ace320",
              color: "black",
              padding: 4,
              width: 80,
              alignSelf: "center",
              fontWeight: 900,
            }}
            disabled={_.isEmpty(isApproved)}
            onClick={() => {
              if (!comment) {
                alert("Feedback Comment Required");
                return;
              }
              setshow(true);
            }}
          >
            Submit
          </button>
          {show && (
            <SendFeedbackModal
              show={show}
              setshow={setshow}
              // Component={FeedBackConfirmationContent}
              onConfirm={onConfirm}
              userFeedbacks={newUserFeedbacks}
              vote={vote}
              loggedUser={loggedUser}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default RightCardProperty;
