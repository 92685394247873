import axios from "axios";
export async function handleRequest(
  url,
  method,
  data = null,
  customHeaders = {}
) {
  // console.log("imgDetails><><", url, method, data, customHeaders);
  try {
    const response = await axios({
      method: method,
      url: url,
      data: data,
      headers: { ...customHeaders },
    });

    return response.data;
  } catch (error) {
    throw error;
  }
}
