import {
  configureStore,
  // buildGetDefaultMiddleware,
  // Tuple,
} from "@reduxjs/toolkit";
import userConditionsReducer from "./UserConditonsSlice";
import LoggedUserReducer from "./LoggedUserSlice";
import ClientConfigReducer from "./ClientConfigDetailsSlice";
import configReducer from "./ConfigSlice";
import AllProjectsSliceReducer from "./AllProjectsSlice";
import AppListSliceReducer from "./AppListSlice";
import AddUserSliceReducer from "./UserSlice";
import TracesReducer from "./TracesSlice";
import LoggedUserDetailsReducer from "./LoggedUserDetailsSlice";
import DownloadLogsReducer from "./DownloadLogsSlice";
// import FeedBackConfirmationContentSlice from "../Store/FeedbackConfirmationSlice";
import UserFeedbackSlice from "./UserFeedbackSlice";
// import logger from "redux-logger";
// import { thunk } from "redux-thunk";

export const store = configureStore({
  reducer: {
    userConditions: userConditionsReducer,
    loggedUser: LoggedUserReducer,
    traces: TracesReducer,
    configData: configReducer,
    clientConfigData: ClientConfigReducer,
    projectsList: AllProjectsSliceReducer,
    appList: AppListSliceReducer,
    usersList: AddUserSliceReducer,
    loggedUserData: LoggedUserDetailsReducer,
    // feedbackData: FeedBackConfirmationContentSlice,
    userFeedbacks: UserFeedbackSlice,
    logsData: DownloadLogsReducer,
  },
  // middleware: () => new Tuple(thunk, logger),
});
