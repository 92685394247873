import { memo } from "react";
import "./Checkbox.css";
const Checkbox = memo(
  ({
    onSelect = () => {},
    isSelected = false,
    style,
    title,
    isDisable,
    count,
  }) => {
    const savedUserDetails = localStorage.getItem("userDetails");
    const userDetails = JSON.parse(savedUserDetails);
    // console.log("json", userDetails.isDisable);
    return (
      <>
        <button
          disabled={userDetails?.isDisable}
          onClick={onSelect}
          id="container"
          style={{
            ...style,
            borderColor: userDetails.isDisable && "#bdeeee",
            backgroundColor: userDetails?.isDisable
              ? "#eceff1"
              : isSelected
              ? "#7c7575"
              : "white",
            color: isSelected ? "white" : "black",
          }}
        >
          {count}
        </button>
        {title ? <h2 id="title">{title}</h2> : null}
      </>
    );
  }
);

export default Checkbox;
