import _ from "lodash";

export const handleSelectTrash = (setTracesData, traceData, traceId) => {
  // console.log("traceData>>", traceData);
  const newTraceData = traceData.map((item, index) => {
    if (item.id === traceId) {
      return {
        ...item,
        isSelected: !item?.isSelected,
      };
    } else {
      return item;
    }
  });
  // console.log("newTraceData", newTraceData);
  setTracesData(newTraceData);
};
export const selectAllTrashes = (traceData, setTracesData) => {
  const isAnyPrevousSelected = isAnySelected(traceData);
  const newTraceData = traceData?.map((item, index) => {
    return {
      ...item,
      isSelected: isAnyPrevousSelected ? false : true,
    };
  });
  setTracesData(newTraceData);
};

const isAnySelected = (traceData) => {
  return _.some(traceData, (item) => item.isSelected);
};

export function filterByAppId(data, appId) {
  // console.log("FF", appId);
  const newfilteredTrashes = _.filter(
    data,
    (item) => _.isEmpty(item.parentRunId) && item.name === "AgentExecutor"
  );

  return newfilteredTrashes.filter((item) => item.app === appId);
}
export function sortByDateLatestToOldest(data) {
  return data.sort(
    (a, b) =>
      new Date(a.date.split("|")[0]).getTime() -
      new Date(b.date.split("|")[0]).getTime()
  );
}
export function sortByDateOldestToLatest(data) {
  return data.sort(
    (a, b) =>
      new Date(b.date.split("|")[0]).getTime() -
      new Date(a.date.split("|")[0]).getTime()
  );
}
export function sortByNodes(nodeData) {
  return nodeData.sort((a, b) => b.count - a.count);
}
export function sortByNodesHtoL(nodeData) {
  return nodeData.sort((a, b) => a.count - b.count);
}

export const selectedTracesByNodes = (
  traceData,
  setTracesData,
  traces,
  setShowSortby
) => {
  const nodeData = traceData?.map((trace) => {
    const tracefilter = traces.filter((tr) => {
      return tr.parentRunId === trace.runId;
    }).length;
    // console.log("TraceFilter", tracefilter);
    return {
      ...trace,
      count: tracefilter,
    };
  });
  // console.log("nodeData", nodeData);
  setTracesData(sortByNodes(nodeData));
  // console.log("updated", updatednodeData);
  // setTracesData(updatednodeData);
  setShowSortby(false);
};
export const selectedTracesByNodesHtoL = (
  traceData,
  setTracesData,
  traces,
  setShowSortby
) => {
  const nodeData = traceData?.map((trace) => {
    const tracefilter = traces.filter((tr) => {
      return tr.parentRunId === trace.runId;
    }).length;
    // console.log("TraceFilter", tracefilter);
    return {
      ...trace,
      count: tracefilter,
    };
  });
  // console.log("nodeData", nodeData);
  setTracesData(sortByNodesHtoL(nodeData));
  // console.log("updated", updatednodeData);
  // setTracesData(updatednodeData);
  setShowSortby(false);
};
