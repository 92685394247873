import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_LOGS } from "../util/ApiEndPoints";

export const getDownloadLogs = createAsyncThunk("getDownloadLogs", async () => {
  const savedUserDetails = localStorage.getItem("userDetails");
  const userDetails = JSON.parse(savedUserDetails);
  const customHeaders = {
    Authorization: `Bearer ${userDetails?.access_token}`,
  };
  try {
    const res = await axios.get(GET_LOGS, { headers: customHeaders });
    if (res.status === 200) {
      return res.data;
    }
  } catch (error) {
    console.log("Error", error);
  }
});

const DownloadLogsSlice = createSlice({
  name: "logsData",
  initialState: {
    logsData: null,
    status: "idle",
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDownloadLogs.pending, (state) => {
        state.status = "Pending";
      })
      .addCase(getDownloadLogs.fulfilled, (state, { payload }) => {
        state.status = "Successed";
        state.logsData = payload;
      })
      .addCase(getDownloadLogs.rejected, (state, action) => {
        state.status = "Failed";
        state.error = action.error.message;
      });
  },
});
export default DownloadLogsSlice.reducer;
