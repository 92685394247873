import React, { useState } from "react";
import "./UserDetails.css";
import { LiaGreaterThanSolid } from "react-icons/lia";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { changeUserAccess } from "../Store/UserConditonsSlice";
import { IoMdCloudUpload } from "react-icons/io";
import { createUser } from "../Store/UserSlice";
// import { useLocation } from "react-router-dom";
import axios from "axios";
const UserDetails = (props) => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [pic, setPic] = useState("");
  const [pics, setPics] = useState("");
  const [picloading, setPicLoading] = useState(false);
  const [title, setTitle] = useState("");
  // const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const savedUserDetails = localStorage.getItem("userDetails");
  const { orgId, authKey } = JSON.parse(savedUserDetails);

  // console.log("userDetail", JSON.parse( savedUserDetails));
  // console.log("titleeeee???", title);
  console.log(pics);
  const sendUserDetails = (e) => {
    e.preventDefault();
    if (
      !firstname ||
      !lastname ||
      !email ||
      !phone ||
      !password ||
      !confirmPassword
    ) {
      alert("Enter All Details");
      return;
    }
    if (password !== confirmPassword) {
      alert("Password and confirmPassword are different");
      return;
    }
    const payload = {
      organisation: {
        orgID: orgId,
      },
      authKey: authKey,
      user: {
        userFirstName: firstname,
        userLastName: lastname,
        emailId: email,
        password: password,
        linkToProfilePhoto: pic,
        title: title,
      },
    };
    try {
      dispatch(createUser(payload));
      dispatch(changeUserAccess(true));
      navigate("/user/maindashboardusers");
    } catch (error) {
      console.log("Error", error);
    }
  };
  const postDetails = async (pics) => {
    setPicLoading(true);
    setPics(pics);
    if (pics === undefined) {
      alert("Please Select an Image!");
      return;
    }
    if (pics.type === "image/jpeg" || pics.type === "image/png") {
      const data = new FormData();
      data.append("file", pics);
      data.append("upload_preset", "YOJN_APP");
      data.append("cloud_name", "doxpfb6la");
      try {
        const res = await axios.post(
          "https://api.cloudinary.com/v1_1/doxpfb6la/image/upload",
          data
        );
        setPic(res.data.url);
        console.log("data", res.data.url);
        setPicLoading(false);
      } catch (error) {
        console.log("Error", error.message);
      }
    } else {
      setPicLoading(false);
      return;
    }
  };
  return (
    <div className="userinformation">
      <div className="userinformationheader">
        <div>YOJN AI</div>
        <div className="useravatar"></div>
      </div>
      <div className="userinformationbody">
        <div className="userinformationbodyheader">
          <p>User Information</p>
          <div className="linktohome">
            <p className="homelink">Home</p>
            <LiaGreaterThanSolid /> Admin
          </div>
        </div>
        <div className="userinformationbodymain">
          <div className="userfirstandlistname">
            <input
              type="text"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              placeholder="First Name *"
              required
            />
            <input
              type="text"
              value={lastname}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Last Name *"
              required
            />
          </div>
          <div className="useremail">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email *"
              required
            />
          </div>
          <div className="useremail">
            <input
              type="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              placeholder="Phone "
              required
            />
          </div>
          <div className="userfirstandlistname">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password *"
              required
            />
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm Password *"
              required
            />
          </div>
          <div>
            <select
              className="addusertitle"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            >
              <option value="Title" style={{ display: "none" }}>
                Title
              </option>
              <option value="Null">Null</option>
              <option value="Admin">Admin</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div className="senduserinformation">
            <div className="userfileuploadfield">
              {picloading ? (
                <h3>Uploading...</h3>
              ) : (
                <label className="custom-file-upload">
                  <input
                    type="file"
                    required
                    onChange={(e) => postDetails(e.target.files[0])}
                  />
                  <p className="picslimit">
                    {pic ? pics.name : "Select Image"} <IoMdCloudUpload />
                  </p>
                </label>
              )}
            </div>
            <button
              className="userinformationsendbutton"
              onClick={() => navigate("/user/maindashboardusers")}
            >
              Back
            </button>
            <button
              className="userinformationsendbutton"
              onClick={(e) => sendUserDetails(e)}
            >
              Send
            </button>
          </div>
        </div>
        <div className="userinformationbodyfooter"></div>
      </div>
    </div>
  );
};

export default UserDetails;
