import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import Navbar from "../../components/subcomponents/Navbar";
import FinetuneBody from "../../components/finetuneComponents/FinetuneBody";
import { useDispatch, useSelector } from "react-redux";
import { getAppList } from "../../Store/AppListSlice";
import { filterByAppId } from "../../components/genricComponent/commonOperations";
// import { getAllBaseProjects } from "../../Store/AllProjectsSlice";
const FinetunePage = () => {
  const [applist, setApplist] = useState([]);
  const savedUserDetails = localStorage.getItem("userDetails");
  const userDetails = JSON.parse(savedUserDetails);
  const dispatch = useDispatch();
  const [traceData, setTracesData] = useState([]);
  const { appList } = useSelector((state) => state?.appList);
  const { traces } = useSelector((state) => state?.traces);
  useEffect(() => {
    const payload = { orgId: userDetails?.orgId };
    dispatch(getAppList({ payload, dispatch }));
  }, [dispatch, userDetails?.orgId]);
  useEffect(() => {
    if (appList) {
      setApplist(appList);
    }
  }, [appList]);

  useEffect(() => {
    const filterApp = (app) => {
      const traceAr = filterByAppId(traces, app?.app_id);
      setTracesData(traceAr);
    };
    filterApp(applist[0]);
  }, [applist, traces]);
  const filterApp = (app) => {
    const traceAr = filterByAppId(traces, app?.app_id);
    setTracesData(traceAr);
  };
  // console.log("applist?????", appList);
  return (
    <div className="maindashboard">
      <Sidebar />
      <div className="maindashboardmaincontent">
        <Navbar
          appList={applist}
          setApplist={setApplist}
          filterByApp={filterApp}
        />
        <FinetuneBody
          activeApp={applist?.find((app) => app?.isSelected) || applist[0]}
          traceData={traceData}
          setTracesData={setTracesData}
        />
      </div>
    </div>
  );
};

export default FinetunePage;
