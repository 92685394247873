const BASE_URL = process.env.REACT_APP_BASE_URL;
// console.log("BASE||", BASE_URL);
//auth
export const LOGIN = `${BASE_URL}rlhf/login`;

//users
export const ADD_USERS = `${BASE_URL}playground/addUserORID`;

//traces
export const GET_TRACES = `${BASE_URL}tracedb/getTracesByOrgId/`;
export const ADD_TRACES = `${BASE_URL}rlhf/addTrace`;

//projects
export const ADD_PROJECT = `${BASE_URL}rlhf/addProject`;
export const EDIT_PROJECT = `${BASE_URL}rlhf/editProject`;
export const GET_PROJECT = `${BASE_URL}rlhf/getOrgProject`;
export const GET_BASE_PROJECTS = `${BASE_URL}rlhf/getOrgProjectBase`;
export const GET_PROJECTDETAILS = `${BASE_URL}rlhf/getProjectDeatils`;
export const GET_PROJECTVERISON = `${BASE_URL}rlhf/getVersionProjects`;
export const GET_COMBINEFEEDBACK = `${BASE_URL}rlhf/combineFeedback`;

//apps
export const GET_APPS = `${BASE_URL}configDeveloper/getAppCD/`;
export const CREATE_APP = `${BASE_URL}rlhf/createApp`;
export const GET_USERS = `${BASE_URL}rlhf/getApp `;
export const EDIT_APP = `${BASE_URL}rlhf/editAppDetails`;

// feeback model
export const COMMENT_FEEDBACK = `${BASE_URL}rlhf/addComment`;
export const GET_FEEDBACKS = `${BASE_URL}rlhf/getComment`;

//Download Logs
export const GET_LOGS = `${BASE_URL}rlhf/api/getlog`;
//Edituserdetail
export const EDIT_USER = `${BASE_URL}configUser/updateUserDetails`;
export const EDIT_USERPassword = `${BASE_URL}configUser/updatePassword`;

// Get finetune status

export const GET_FINETUNE_STATUS = `${BASE_URL}rlhf/getFinetuneStatus`;
export const RUN_FINETUNE_RLHF = `${BASE_URL}rlhf/runFinetuneRlhf`;
export const FINETUNE_STATUS = `${BASE_URL}rlhf/finetuneStatus`;

// AB Testing
export const ABTEST_DOWNLOAD = `${BASE_URL}/rlhf/abtestdownload`;
