import * as React from "react";
import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";
import { useState } from "react";
// import { useEffect } from "react";
import WeightConfirmModal from "./WeightConfirmModal";
import "./UpdateWeightsModal.css";
import _ from "lodash";

export default function UpdateWeightsModal({
  show,
  setshow,
  updatedUsersList,
  setUpdatedUsersList,
  setUpdateWeight,
  setIsUpdateWeight,
}) {
  const [weightconfirm, setshowweightconfirm] = useState(false);
  const [latestUsersList, setLatestUsersList] = useState([...updatedUsersList]);

  // const [userWightage, setUserWeightage] = useState("");
  // console.log("usersss", updatedUsersList);
  // console.log("roleeeeeee", userWightage);

  const handleuserWeightage = (value, id) => {
    console.log("valueandid", value, id);
    const newUsersList = _.map(latestUsersList, (user) => {
      if (user.emailId === id) {
        return {
          ...user,
          weightage: value,
        };
      } else {
        return {
          ...user,
        };
      }
    });
    setLatestUsersList(newUsersList);
  };

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={show}
      onClose={() => setshow(false)}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "md",
          pb: 0,
          boxShadow: "sm",
          background: "white",
          outline: "none",
        }}
      >
        <div className="updateweightsmodal">
          <div className="updateweightsmodaltop">
            <div>Name</div>
            <div>Weights</div>
          </div>
          <div className="updateweightsmodaltopbody">
            {latestUsersList?.map((user) => (
              <div
                key={user?.emailId}
                className="updateweightsmodaltopbodychild"
              >
                <div>{user?.userFirstName}</div>
                <div>
                  <select
                    value={user?.weightage}
                    onChange={(e) =>
                      handleuserWeightage(e.target.value, user.emailId)
                    }
                  >
                    <option value="Advanced">Advanced</option>
                    <option value="Beginner">Beginner</option>
                    <option value="Intermediatery">Intermediatery</option>
                  </select>
                </div>
              </div>
            ))}
          </div>
          <div className="updateweightsmodalfooter">
            <button onClick={() => setshow(false)}>Back</button>
            <button onClick={() => setshowweightconfirm(true)}>Next</button>
          </div>
        </div>
        {weightconfirm && (
          <WeightConfirmModal
            show={weightconfirm}
            setshow={setshowweightconfirm}
            disbaleWeights={setshow}
            setUpdatedUsersList={setUpdatedUsersList}
            latestUsersList={latestUsersList}
            setUpdateWeight={setUpdateWeight}
            setIsUpdateWeight={setIsUpdateWeight}
          />
        )}
      </Sheet>
    </Modal>
  );
}
