import React, { useEffect } from "react";
import Sidebar from "../components/Sidebar";
import MainDashboardNavbar from "../components/subcomponents/MainDashboardNavbar";
import "./DownloadLogs.css";
import LogsBody from "../components/LogsBody";
import { useDispatch } from "react-redux";
import { getDownloadLogs } from "../Store/DownloadLogsSlice";
import { getAppList } from "../Store/AppListSlice";
const DownloadLogs = () => {
  const savedUserDetails = localStorage.getItem("userDetails");
  // const { appList } = useSelector((state) => state?.appList);
  const userDetails = JSON.parse(savedUserDetails);
  const dispatch = useDispatch();

  useEffect(() => {
    let payload = { orgId: userDetails?.orgId };
    dispatch(getAppList({ payload, dispatch }));
  }, [dispatch, userDetails?.orgId]);

  useEffect(() => {
    dispatch(getDownloadLogs());
  }, [dispatch]);

  return (
    <div className="logs">
      <Sidebar />
      <div className="mainlogs">
        <MainDashboardNavbar />
        <LogsBody />
      </div>
    </div>
  );
};

export default DownloadLogs;
