import React from "react";
import "./MainDashboard.css";
import MainDashboardMainContent from "../components/MainDashboardMainContent";
import Sidebar from "../components/Sidebar";

const MainDashboard = () => {
  return (
    <div className="maindashboard">
      <Sidebar />
      <MainDashboardMainContent />
    </div>
  );
};

export default MainDashboard;
