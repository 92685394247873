import React from "react";
import "./DashboardNavbar.css";
import newYojnLogo1 from "../../Assets/newYojnLogo1.png";
import { Link } from "react-router-dom";
const DashboardNavbar = () => {
  return (
    <div className="dashboardnavbar">
      <div className="dashboardnavbarlogo">
        <img src={newYojnLogo1} alt="Logo" className="landingpagelogo" />
      </div>
      <div className="dashboardnavbarlist">
        <Link to={"/platform"} className="dashboardnavbarlistitems">
          Platform
        </Link>
        <Link to={"/Pricing"} className="dashboardnavbarlistitems">
          {" "}
          Pricing{" "}
        </Link>
        <Link to={"Learn"} className="dashboardnavbarlistitems">
          Learn
        </Link>
        <Link to={"Company"} className="dashboardnavbarlistitems">
          {" "}
          Company
        </Link>
      </div>

      <div className="dashboardnavbarlogin">
        <Link to={"/login"} className="dashboardnavsignup">
          Signup/Login
        </Link>
      </div>
    </div>
  );
};

export default DashboardNavbar;
