import React from "react";
import "./DashboardFooter.css";
import { Link } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
const DashboardFooter = () => {
  return (
    <div className="dashboardfooter">
      <div className="dashboardfooterupperchild">
        <div className="dashboardfooterupperfirstclild">
          <div className="dashboardfooterupperfirstclildlist">
            <h2>Platform</h2>
            <Link className="dashboardfooterupperfirstclildlink">Overview</Link>
            <Link className="dashboardfooterupperfirstclildlink">
              ML observability
            </Link>
            <Link className="dashboardfooterupperfirstclildlink">
              ML monitoring
            </Link>
            <Link className="dashboardfooterupperfirstclildlink">
              Model metric store
            </Link>
            <Link className="dashboardfooterupperfirstclildlink">
              Documentation
            </Link>
            <Link className="dashboardfooterupperfirstclildlink">Pricing</Link>
            <Link
              to={
                "https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ2txrOjvrfgkHh5u6mHrsEDrFKisnJjIOsbszChbBRHC6B9eLZSX4zr006y7izItbJ9-KJr0TGS"
              }
              className="dashboardfooterupperfirstclildlink"
            >
              Request a demo
            </Link>
          </div>
          <div className="dashboardfooterupperfirstclildlist">
            <h2>Learn</h2>
            <Link className="dashboardfooterupperfirstclildlink">Blog</Link>
            <Link className="dashboardfooterupperfirstclildlink">
              Recources
            </Link>
            <Link className="dashboardfooterupperfirstclildlink">ML Talks</Link>
            <Link className="dashboardfooterupperfirstclildlink">
              Drift in machine learning
            </Link>
            <Link className="dashboardfooterupperfirstclildlink">
              ML monitoring e-book
            </Link>
          </div>
          <div className="dashboardfooterupperfirstclildlist">
            <h2>Company</h2>
            <Link className="dashboardfooterupperfirstclildlink">About us</Link>
            <Link className="dashboardfooterupperfirstclildlink">News</Link>
            <Link className="dashboardfooterupperfirstclildlink">Events</Link>
            <Link className="dashboardfooterupperfirstclildlink">Careers</Link>
            <Link className="dashboardfooterupperfirstclildlink">
              Contact us
            </Link>
          </div>
        </div>
        <div className="dashboardfooteruppersecondchild">
          <div className="dashboardfoteruppersecondchildlist">
            <h1>Get Our Newsletter</h1>
          </div>
          <div className="contactemailinput">
            <input type="text" placeholder="email*" />
            <div>
              <FaArrowRightLong />
            </div>
          </div>
          <div>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit,
              possimus! Blanditiis ipsa et veniam maxime quibusdam est suscipit?
              Harum molestiae ullam ex numquam laudantium obcaecati quisquam
              esse odio repellendus,
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardFooter;
