import React, { useEffect, useState } from "react";
import "./MainDashboardMainBody.css";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { allowCreateProject } from "../../Store/UserConditonsSlice";
import { MdModeEdit } from "react-icons/md";
import { getAllProjects } from "../../Store/AllProjectsSlice";
import IfProjectCreated from "./IfProjectCreated";
import applogo from "../../Assets/applogo.png";
import { getUsers } from "../../Store/UserSlice";
// import { handleRequest } from "../../api/apiCall";
import { createAppfun } from "../../Store/AppListSlice";

const MainDashboardMainBody = () => {
  const userConditions = useSelector((state) => state.userConditions);

  const { baseProjectsList } =
    useSelector((state) => state?.projectsList) || [];
  const { appList } = useSelector((state) => state?.appList);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeApp, setActiveApp] = useState(null);
  const savedUserDetails = localStorage.getItem("userDetails");
  const userDetails = JSON.parse(savedUserDetails);
  // console.log("console.log==activeapp", activeApp);

  useEffect(() => {
    if (appList) {
      setActiveApp(appList[0]);
    }
  }, [appList]);

  const handleAppClick = (app) => {
    console.log("App||", app);
    setActiveApp(app);
    dispatch(
      createAppfun({
        appId: app?.app_id,
        orgId: userDetails?.orgId,
        baseAppId: "",
      })
    );
    // if (userConditions.isuserAccess) {
    dispatch(
      getUsers({
        orgId: userDetails?.orgId,
        appId: app?.app_id,
      })
    );
    // } else {
    dispatch(
      getAllProjects({
        orgId: userDetails?.orgId,
        appId: app?.app_id,
      })
    );
    // }
  };
  const handleuserprojectlist = (project) => {
    // dispatch(getuserProject(project));
    dispatch(allowCreateProject(true));
    navigate("/user/adminanduserprojectslist", { state: { project: project } });
  };

  const onCreateProject = () => {
    dispatch(allowCreateProject(true));
    // navigate("/user/adminanduserprojectslist", {
    //   state: { activeApp: activeApp },
    // });
  };

  if (userConditions.iscreateProject) {
    // console.log(userConditions);
    return <IfProjectCreated activeApp={activeApp} />;
  }
  return (
    <div className="maindashboardmainbody">
      <div className="maindashboardmainbodyheader">
        <div className="appandcreateuers">
          <h2>Apps</h2>
        </div>
        <div className="maindashboardmainbodyheaderapplist">
          {appList?.map((app, index) => (
            <div key={index} className="Appslist">
              <div
                onClick={() => handleAppClick(app)}
                className={`firstapp ${
                  activeApp?.app_id === app?.app_id ? "activeapp" : ""
                }`}
              >
                <img src={applogo} alt="applogo" className="applistlogo" />
              </div>
              <h4>{app.appName}</h4>
            </div>
          ))}
        </div>
      </div>

      <div className="maindashboardmainbodyfooter">
        <div className="projectsandcreatebutton">
          <h2>Projects</h2>
          <Link
            to={"/user/adminanduserprojectslist"}
            className="projectlinkbutton"
            onClick={onCreateProject}
          >
            <div className="createprojectsbutton">Create</div>
          </Link>
        </div>

        <div className="maindashboardprojectslist">
          <div className="maindashboardprojectdetail2">
            <div>
              <h2>Name</h2>
            </div>
            <div className="projectobjective">
              <h2>RLHF Objective</h2>
            </div>
            <div></div>
          </div>
          <div className="maindashboardprojectdetail3">
            {baseProjectsList?.map((project, index) => (
              <div key={index} className="maindashboardprojectdetail2">
                <p>{project.projectName}</p>
                <p className="projectobjective">{project.rlhfObjective}</p>
                <MdModeEdit
                  size={20}
                  className="editusericon"
                  onClick={() => handleuserprojectlist(project)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainDashboardMainBody;
