import * as React from "react";
import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";
// import { useState } from "react";
// import { useEffect } from "react";
import "./WeightConfirmModal.css";
import { useDispatch } from "react-redux";
import {
  // addProject,
  startTrainingProject,
} from "../../../Store/AllProjectsSlice";
// import { ADD_PROJECT, EDIT_PROJECT } from "../../../util/ApiEndPoints";
export default function StartTrainingModal({
  show,
  setshow,
  activeProject,
  updatedUsersList,
  collectedMergeTraces,
  ismergeTrace,
  isUpdateWeight,
  orgId,
}) {
  //   const [showweightconfirm, setshowweightconfirm] = useState(false);
  console.log("activeProject", activeProject);
  // console.log("updatedUsersList>>", updatedUsersList);
  console.log("isUpdateWeight", isUpdateWeight, "mergetrace", ismergeTrace);
  const [tempUpdatedUserslist, setTempUpdatedUsersList] = React.useState([]);
  const [parameterX, setParameterX] = React.useState("");
  const dispatch = useDispatch();
  console.log("parameter", parameterX);
  React.useEffect(() => {
    const filteredUpdatedUsersList = updatedUsersList.map((user) => {
      return {
        emailId: user.emailId,
        role: user.role,
        weightage: user.weightage,
      };
    });
    setTempUpdatedUsersList(filteredUpdatedUsersList);
    // console.log("filteredUpdatedUsersList", filteredUpdatedUsersList);
  }, [updatedUsersList]);

  React.useEffect(() => {
    if (isUpdateWeight === "Yes" && ismergeTrace === "Yes") {
      setParameterX("1");
    } else if (isUpdateWeight === "Yes" && ismergeTrace === "No") {
      setParameterX("2");
    } else if (isUpdateWeight === "No" && ismergeTrace === "Yes") {
      setParameterX("3");
    } else if (isUpdateWeight === "No" && ismergeTrace === "No") {
      setParameterX("4");
    }
  }, [isUpdateWeight, ismergeTrace]);
  const handleStartTraining = () => {
    setshow(false);
    const payload = {
      appId: activeProject?.appid,
      orgId: orgId,
      projectName: activeProject?.projectName,
      rlhfObjective: activeProject?.rlhfObjective,
      traceId: activeProject?.traceId,
      baseVersionId: activeProject?.projectId || activeProject?.projectID,
      x: parameterX,
    };
    dispatch(
      startTrainingProject({
        payload,
        dispatch,
        tempUpdatedUserslist,
        activeProject,
        collectedMergeTraces,
      })
    );
  };

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={show}
      onClose={() => setshow(false)}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "md",
          pb: 0,
          boxShadow: "sm",
          background: "white",
        }}
      >
        <div className="weightconfirmmodal">
          <div className="weightconfirmmodalheader">Training Confirmation</div>
          <div className="weightconfirmmodalbody">
            Do you want to start the training of the data?
          </div>
          <div className="weightconfirmmodalfooter">
            <button onClick={() => setshow(false)}>Back</button>
            <button
              onClick={() => {
                // setshowweightconfirm(false);
                handleStartTraining();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Sheet>
    </Modal>
  );
}
