import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_USERS, ADD_USERS } from "../util/ApiEndPoints";
export const createUser = createAsyncThunk("createUser", async (payload) => {
  // console.log("payload:|:", payload);
  try {
    const res = await axios.post(ADD_USERS, payload);
    // console.log("userStatus", res.status);
    return res.status;
  } catch (error) {
    console.log("Error", error);
  }
});

export const getUsers = createAsyncThunk("getUsers", async (payload) => {
  const savedUserDetails = localStorage.getItem("userDetails");
  const userDetails = JSON.parse(savedUserDetails);

  // console.log("userDetails<><>", userDetails);
  // console.log("payload<><>", payload);
  const customHeaders = {
    Authorization: `Bearer ${userDetails.access_token}`,
  };
  try {
    const res = await axios.post(
      GET_USERS,
      { appId: payload.appId, orgId: payload?.orgId },
      {
        headers: customHeaders,
      }
    );
    // console.log("userStatus list", res.data);
    return res.data;
  } catch (error) {
    console.log("Error", error);
  }
});

const AddUserSlice = createSlice({
  name: "Adduser",
  initialState: {
    usersList: [],
    status: "idle",
    error: null,
  },
  reducers: {
    addUser: (state, { payload }) => {
      // console.log("payload", payload);
      return {
        ...state,
        usersList: payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state) => {
        state.status = "Pending";
      })
      .addCase(createUser.fulfilled, (state, { payload }) => {
        state.status = payload;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.status = action.error.message;
      })
      .addCase(getUsers.pending, (state) => {
        state.status = "Pending";
      })
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        state.usersList = payload;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.error = action.error.message;
      });
  },
});

// export const { addUser } = AddUserSlice;
export default AddUserSlice.reducer;
