const UserCard = ({ usr }) => {
  // console.log('usr::',usr);
  return (
    <div className="rightcardbodycontentchild">
      <div className="userproperties">
        {/* <div className="userdetails"> */}
        <div className="circleandname">
          <div className="circulardiv">
            <img src={usr?.userImage} alt="" className="userimage" />
          </div>
          <p className="username">{usr.firstName} </p>
          <p className="username"> {usr.lastName}</p>
        </div>
        {/* <div className="advanceandapproved"> */}
        <p className="advanced">{usr.weightage}</p>
        <p
          className={`detailsapproved ${
            usr.vote === 0 && "detailsdisapproved"
          }`}
        >
          {usr.vote === 1 ? "Approval" : "DisApproval"}
        </p>
        {/* </div> */}
        {/* </div> */}
        <p className="approveddate">{usr.date}</p>
      </div>
      <div className="usercomment">Feedback: {usr.comment}</div>
    </div>
  );
};

export default UserCard;
