import React, { useEffect, useState } from "react";
import "./LogsBody.css";
import { useSelector } from "react-redux";
import _ from "lodash";

const LogsBody = () => {
  const [showDate, setShowDate] = useState(false);
  const { logsData } = useSelector((state) => state?.logsData);
  // console.log("LogsData", logsData);
  const { appList } = useSelector((state) => state?.appList);
  // console.log("Appsss", appList);

  const { status } = useSelector((state) => state?.logsData);
  const [newLogsData, setNewLogsData] = useState([]);
  // console.log("logData", newLogsData);
  const [selectedApp, setSelectedApp] = useState("Apps");
  const [date1, setDate1] = useState("");
  const [date2, setDate2] = useState("");
  const [clicked, setClickedApp] = useState(false);

  useEffect(() => {
    if (!_.isEmpty(logsData)) {
      setNewLogsData(logsData);
    }
  }, [logsData]);

  const getLogDate = (log) => {
    return log.timestamp.split(" ")[0];
  };

  const getLogTime = (log) => {
    return log.timestamp.split(" ")[1];
  };

  const DownloadLogsSlice = () => {
    const csvContent = [
      ["Date", "Time", "Information"],
      ...newLogsData.map((log) => [log.timestamp, log.level, log.message]),
    ];

    const csvData = csvContent.map((row) => row.join(",")).join("\n");

    const blob = new Blob([csvData], { type: "text" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "logs.text");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleAppChange = (e) => {
    const selectedAppId = e.target.value;

    // If "Apps" is selected, show all logs
    if (selectedAppId === "Apps") {
      setNewLogsData(logsData);
    } else {
      // Filter logs based on the selected app
      const appwiseLogs = logsData.filter((log) => log.appId === selectedAppId);
      setNewLogsData([...appwiseLogs]);
      setClickedApp(true);
    }

    setSelectedApp(selectedAppId);
  };

  const filterByDateData = () => {
    const filterDate = _.filter(newLogsData, (log) => {
      const logdate = log.timestamp.split(" ")[0];
      // console.log("logdata", logdate);
      const logTimestamp = new Date(logdate).getTime();
      // console.log("logdateTimestamp", logTimestamp);
      const filterTimeStampFrom = Number(new Date(date1).getTime());
      const filterTimeStampTo = Number(new Date(date2).getTime());
      // console.log("filterTimeStampFrom", filterTimeStampFrom);
      // console.log("date1", date1);
      // console.log("date2", date2);
      // console.log("logdate", logdate);
      // console.log("filterTimeStampTo", filterTimeStampTo);
      if (
        !isNaN(filterTimeStampTo) &&
        !isNaN(filterTimeStampFrom) &&
        logTimestamp >= filterTimeStampFrom &&
        logTimestamp <= filterTimeStampTo
      ) {
        return { ...log };
      }
    });
    // console.log("filterData", filterDate);
    setNewLogsData(filterDate);
    setShowDate(false);
    setDate1("");
    setDate2("");
  };

  const handleRestoreDefault = () => {
    setSelectedApp("Apps");
    setNewLogsData(logsData);
    setClickedApp(false);
  };

  return (
    <div className="logsbody">
      <div className="logdiv1">
        <p>Activity Logs</p>
        <button onClick={DownloadLogsSlice}>Download Logs</button>
      </div>
      <div className="logdiv2">
        <div className="logdiv2button1">
          <div
            className="logdiv2dateselect"
            style={{
              background: showDate ? "#256358" : "",
              color: showDate ? "white" : "",
            }}
            onClick={() => setShowDate(!showDate)}
          >
            Date
          </div>
          {showDate && (
            <div className="logdivdate">
              <div className="logdivdatediv1">
                <h3>Select Date Range</h3>
                <h1
                  onClick={() => setShowDate(false)}
                  className="logdivdateexit"
                >
                  X
                </h1>
              </div>
              <div className="logdivdateinput">
                <h3>From:</h3>
                <input
                  type="text"
                  value={date1}
                  onChange={(e) => setDate1(e.target.value)}
                  placeholder="YYYY-MM-DD"
                />
              </div>
              <hr />
              <div className="logdivdateinput">
                <h3>To:</h3>
                <input
                  type="text"
                  value={date2}
                  onChange={(e) => setDate2(e.target.value)}
                  placeholder="YYYY-MM-DD"
                />
              </div>
              <div className="logdivdatediv4">
                <button onClick={() => filterByDateData()}>Apply</button>
                <button
                  onClick={() => {
                    setDate1("");
                    setDate2("");
                  }}
                >
                  Clear
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="lodiv2appslistbutton">
          <select
            className={`logapps ${clicked && "logappclicked"}`}
            value={selectedApp}
            onChange={handleAppChange}
          >
            <option value="Apps" style={{ display: "none" }}>
              Apps
            </option>
            {appList?.map((app) => (
              <option key={app.app_id} value={app.app_id}>
                {app.app_id}
              </option>
            ))}
          </select>
        </div>
        <button className="logsrestore" onClick={() => handleRestoreDefault()}>
          Restore Default
        </button>
      </div>
      <div className="logdiv3">
        <div className="logsdatadiv1">
          <h2>Date</h2>
          <h2>Time</h2>
          <h2>App</h2>
          <h2>Information</h2>
        </div>
        <div className="logsdatadiv2">
          {status === "Pending" ? (
            <h1 className="logsloading">Loading...</h1>
          ) : (
            newLogsData
              ?.slice()
              ?.reverse()
              ?.map((log, index) => (
                <div key={index}>
                  <p>{getLogDate(log)}</p>
                  <p>{getLogTime(log)}</p>
                  <p>{log.appId}</p>
                  <p>{log.message}</p>
                </div>
              ))
          )}
        </div>
      </div>
    </div>
  );
};

export default LogsBody;
