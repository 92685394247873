import React from "react";
import DashboardNavbar from "../components/dashboardComponents/DashboardNavbar";
import DashboardBody from "../components/dashboardComponents/DashboardBody";
import "./Dashboard.css";
import DashboardFooter from "../components/dashboardComponents/DashboardFooter";
const Dashboard = () => {
  return (
    <div className="dashboard">
      <DashboardNavbar />
      <DashboardBody />
      <DashboardFooter />
    </div>
  );
};

export default Dashboard;
