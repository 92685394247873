import * as React from "react";
// import Typography from "@mui/joy/Typography";
import _ from "lodash";
import Checkbox from "./Checkbox";
// import { ModalClose } from "@mui/joy";
import "./Checkbox.css";

const SaveTrashModalContent = ({ setshow, otherArgs }) => {
  const { handleSaveTrash, selectProject, projectsList = [] } = otherArgs;
  // console.log("setshow", setshow);

  // console.log("otherArgs:", projectsList);
  const projects = _.map(projectsList, (item, index) => {
    return (
      <div key={index} id="listWraper">
        <Checkbox
          onSelect={() => selectProject(item.projectId)}
          isSelected={item.isSelected}
          style={{ width: "3px", height: "3px", cursor: "pointer" }}
          isDisable={false}
        />
        <label>{item.projectName}</label>
      </div>
    );
  });
  return (
    <div className="saveContentModalclass">
      <div id="saveContentContainer">
        {/* <div> */}
        <div style={{ marginLeft: 15 }}>Feedback Confirmation</div>
        {/* </div> */}
        {/* <ModalClose variant="plain" sx={{ mt: -0.5 }} /> */}
        <button className="closemodalbutton" onClick={() => setshow(false)}>
          X
        </button>
      </div>

      <div className="appprojectslist">{projects}</div>
      <button
        onClick={handleSaveTrash}
        disabled={!_.some(projectsList, (proj) => proj?.isSelected)}
        style={{
          padding: 5,
          backgroundColor: "#D2E998",
          border: "none",
          fontWeight: "bold",
          cursor: "pointer",
        }}
      >
        Submit
      </button>
      {/* <div>
        <Typography id="modal-desc" textColor="text.tertiary"></Typography>
      </div> */}
    </div>
  );
};
export default SaveTrashModalContent;
