import React, { useEffect, useState } from "react";
import "./CardDetails.css";
import LeftCardProperty from "../components/subcomponents/LeftCardProperty";
import RightCardProperty from "../components/subcomponents/RightCardProperty";
import { useLocation } from "react-router-dom";
import _ from "lodash";
// import { COMMENT_FEEDBACK } from "../util/ApiEndPoints";
// import { handleRequest } from "../api/apiCall";
import { useDispatch } from "react-redux";
import {
  getUsersFeedbacks,
  // getUsersFeedbacks,
  // getUsersFeedbacks,
  sendUserFeedback,
} from "../Store/UserFeedbackSlice";
import { getAllBaseProjects } from "../Store/AllProjectsSlice";

// const usrsFeebackResponse = [
//   {
//     userId: "2333",
//     profileImg: "https:://",
//     userName: "sk deer",
//     comment: "hey there this one plus",
//     status: "disApprove",
//     weitage: "advanced",
//     date: "10-04-2000",
//   },
// ];
const CardDetails = () => {
  // console.log("userFeedbacks:", userFeedbacks);
  const { state } = useLocation();
  // console.log("state:", state);
  const savedUserDetails = localStorage.getItem("userDetails");
  const userDetails = JSON.parse(savedUserDetails);
  const [subTraces, setSubTraces] = useState([]);
  const [clickedTrace, setclickedTrace] = useState([]);
  // console.log("subTraces", subTraces);
  // console.log("clickedTrace", clickedTrace);
  const [comment, setComment] = useState("");
  const dispatch = useDispatch();

  const sendFeedback = async (feedback) => {
    // const savedUserDetails = localStorage.getItem("userDetails");
    // const userDetails = JSON.parse(savedUserDetails);
    // const customHeaders = {
    //   Authorization: `Bearer ${userDetails.access_token}`,

    // };
    dispatch(
      sendUserFeedback({ feedback, dispatch, orgId: userDetails?.orgId })
    );
    setComment("");
  };
  useEffect(() => {
    if (state?.data) {
      setSubTraces([...state?.data]);
    }
  }, [state?.data]);
  useEffect(() => {
    dispatch(
      getAllBaseProjects({
        appId: state?.activeApp.app_id,
        orgId: userDetails?.orgId,
      })
    );
  }, [dispatch, state?.activeApp.app_id, userDetails?.orgId]);

  useEffect(() => {
    if (!_.isEmpty(state?.data)) {
      const selectFirTraces = _?.map(state?.data, (itm, index) => {
        if (index === 0) {
          return {
            ...itm,
            isSelected: true,
          };
        } else {
          return {
            ...itm,
            isSelected: false,
          };
        }
      });
      setSubTraces(selectFirTraces);
      setclickedTrace(_?.find(selectFirTraces, (item) => item?.isSelected));
    }
  }, [state?.data]);

  const onSelectSubTrace = (itm) => {
    console.log("item>?>?", itm);
    // console.log("subtraces>>", subTraces);
    const newSubTraces = _.map(subTraces, (tr) => {
      if (tr.id === itm.id) {
        return {
          ...tr,
          isSelected: true,
        };
      } else {
        return {
          ...tr,
          isSelected: false,
        };
      }
    });
    setclickedTrace(itm);
    dispatch(
      getUsersFeedbacks({
        traceId: itm?.id,
        appId: state?.activeApp.app_id,
        orgId: userDetails?.orgId,
      })
    );
    setSubTraces(newSubTraces);
  };

  useEffect(() => {
    if (state) {
      dispatch(
        getUsersFeedbacks({
          traceId: state.data[0]?.id,
          appId: state?.activeApp.app_id,
          orgId: userDetails?.orgId,
        })
      );
    }
  }, [dispatch, state]);

  return (
    <div className="cardproperty">
      <LeftCardProperty
        state={subTraces}
        patentTrace={state?.trace}
        clickedTrace={clickedTrace}
        setclickedTrace={setclickedTrace}
        onSelectSubTrace={onSelectSubTrace}
      />
      <RightCardProperty
        clickedTrace={clickedTrace}
        // userFeedbacks={userFeedbacks}
        sendFeedback={sendFeedback}
        activeApp={state?.activeApp}
        setComment={setComment}
        comment={comment}
        state={state}
      />
    </div>
  );
};

export default CardDetails;
