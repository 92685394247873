import * as React from "react";
import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";
// import { useState } from "react";
// import { useEffect } from "react";
import "./WeightConfirmModal.css";
export default function ProgressFinetuningModal({
  show,
  setshow,
  handleRunFinetune,
  // setShowFinetuneStarted,
}) {
  //   const [showweightconfirm, setshowweightconfirm] = useState(false);
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={show}
      onClose={() => setshow(false)}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "md",
          pb: 0,
          boxShadow: "sm",
          background: "white",
        }}
      >
        <div className="weightconfirmmodal">
          <div className="weightconfirmmodalheader">
            Finetuning Confirmation
          </div>
          <div className="weightconfirmmodalbody">
            Do you want to start the Finetuning process?
          </div>
          <div className="weightconfirmmodalfooter">
            <button onClick={() => setshow(false)}>Back</button>
            <button
              onClick={() => {
                // setshowweightconfirm(false);
                handleRunFinetune();
                setshow(false);
                // setShowFinetuneStarted(true);
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Sheet>
    </Modal>
  );
}
