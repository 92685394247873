import React from "react";
import "./PMDashboardNavbar.css";
import { getAllBaseProjects } from "../../Store/AllProjectsSlice";
import { useDispatch } from "react-redux";
const PMDashboardNavbar = ({ appList, setActiveApp }) => {
  const savedUserDetails = localStorage.getItem("userDetails");
  const userDetails = JSON.parse(savedUserDetails);
  // console.log("applistandselectedapp", appList, selectedApp);
  const dispatch = useDispatch();
  const handleAppSelected = (val) => {
    let app = appList.find((app) => app.app_id === val);
    setActiveApp(app);
    dispatch(
      getAllBaseProjects({ orgId: userDetails?.orgId, appId: app.app_id })
    );
    // console.log("appppp", app);
  };

  return (
    <div className="pmdashboardnavbar">
      {/* <div > */}
      <select
        className="pmdashboardnavbarapplist"
        onChange={(e) => handleAppSelected(e.target.value)}
      >
        {/* <option style={{ display: "none" }}>Apps</option> */}
        {appList?.map((app) => (
          <option key={app.app_id} value={app.app_id}>
            {app.appName}
          </option>
        ))}
      </select>
      {/* </div> */}
    </div>
  );
};

export default PMDashboardNavbar;
