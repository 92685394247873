import * as React from "react";
import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";
// import { useState } from "react";
// import { useEffect } from "react";
import "./MergeTraceModal.css";
import _ from "lodash";
// import { Checkbox } from "@mui/joy";
import Checkbox from "../../genricComponent/Checkbox";
import { ImArrowUpRight } from "react-icons/im";
import { ImArrowDownLeft } from "react-icons/im";
import { useSelector } from "react-redux";
import { handleSelectTrash } from "../../genricComponent/commonOperations";
export default function MergeTraceModal({
  show,
  setshow,
  traceIDList,
  traceData,
  projectVersionsList,
  setTracesData,
  setCollectedMergeTraces,
  setMergeExistingTrace,
  setIsMergeTrace,
}) {
  //   const [showweightconfirm, setshowweightconfirm] = useState(false);
  const { traces } = useSelector((state) => state?.traces);
  const [filterData, setFilterData] = React.useState([]);
  const [handleWidth, setHandleWidht] = React.useState(false);
  let collectedMergeTraces = [];
  // console.log("traceidlist", traceIDList);
  // console.log("traceData", traceData);
  // console.log("projectVersionsList", projectVersionsList);
  console.log("filterData", filterData);
  React.useEffect(() => {
    const diffList = traceIDList?.filter(
      (trace) => !projectVersionsList?.includes(trace)
    );
    // console.log("diffList", diffList);

    const updatedTraces = traceData
      .filter((trace) => diffList.includes(String(trace.id)))
      .map((trace) => ({ ...trace, isSelected: false }));

    console.log("updatedTraces", updatedTraces);
    setFilterData(updatedTraces);
  }, [projectVersionsList, traceData, traceIDList]);

  // console.log("projectTraces|||", projectTraces);
  const handeMergeTraces = () => {
    _.map(filterData, (trace) => {
      if (trace.isSelected) {
        collectedMergeTraces.push(String(trace.id));
      }
    });
    setCollectedMergeTraces(collectedMergeTraces);
    setshow(false);
    setMergeExistingTrace(true);
    setIsMergeTrace("Yes");
  };

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={show}
      onClose={() => setshow(false)}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "10px",
          pb: 0,
          boxShadow: "sm",
          background: "white",
          width: handleWidth ? "100%" : "79%",
          height: handleWidth ? "100%" : "60%",
        }}
      >
        <div
          className={`mergetracemodal ${!handleWidth && "mergetracemodal2"}`}
        >
          <div
            className={`mergetracemodalheader ${
              !handleWidth && "mergetracemodalheader2"
            }`}
          >
            {handleWidth ? (
              <ImArrowDownLeft
                size={25}
                cursor={"pointer"}
                onClick={() => setHandleWidht(false)}
              />
            ) : (
              <ImArrowUpRight
                size={25}
                cursor={"pointer"}
                onClick={() => setHandleWidht(true)}
              />
            )}
          </div>
          <div className="mergetracemodalbodycontent">
            {filterData.length > 0 ? (
              filterData
                ?.slice()
                .reverse()
                .map((trace) => (
                  <div
                    className="mergetracemodaltrashContainerCard"
                    key={trace.id}
                  >
                    {/* {console.log("isSelected", trace.isSelected)} */}
                    <Checkbox
                      onSelect={() => {
                        handleSelectTrash(setFilterData, filterData, trace.id);
                        // setProjectList(list);
                      }}
                      isSelected={trace?.isSelected}
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                        // display: `${isDisabled ? "none" : ""}`,
                      }}
                    />
                    <div className="cards">
                      <div className="carddetails">
                        <div className="mergetracemodaluserid">#{trace.id}</div>
                        <div className="userquery">{trace.input}</div>
                      </div>
                      <div className="carddetails">
                        <div
                          className="userquery"
                          style={{
                            marginLeft: "20px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {/* <p style={{ fontSize: "13px", fontWeight: "bold" }}>
                          UserId-
                        </p> */}
                          {trace.userId}{" "}
                        </div>
                        <div
                          className={`usernumber ${
                            !handleWidth && "usernumber2"
                          }`}
                        >
                          {
                            _.filter(
                              traces,
                              (tr) => tr.parentRunId === trace.runId
                            ).length
                          }
                        </div>
                        <div>{trace.date}</div>
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <div className="notracefound">No Traces Available</div>
            )}
          </div>
          <div
            className={`mergetracefooter ${
              !handleWidth && "mergetracefooter2"
            }`}
          >
            <button onClick={() => setshow(false)}>Cancel</button>
            <button onClick={() => handeMergeTraces()}>Confirm</button>
          </div>
        </div>
      </Sheet>
    </Modal>
  );
}
