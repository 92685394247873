import React from "react";
import "./Home.css";
import Sidebar from "../components/Sidebar";
import Main from "../components/Main";
// import { useDispatch } from "react-redux";
// import { getConfigDetails } from "../Store/ConfigSlice";
// import { getClientConfigDetails } from "../Store/ClientConfigDetailsSlice";

const Home = () => {
  // const dispatch = useDispatch()
  //   useEffect(() => {
  //     dispatch(getConfigDetails(dispatch));
  //     dispatch(getClientConfigDetails());
  //   }, [dispatch]);
  return (
    <div className="home">
      <Sidebar />
      <Main />
    </div>
  );
};

export default Home;
