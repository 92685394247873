import React, { useEffect, useState } from "react";
import "./MainDashboardMainBody.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { getAllBaseProjects } from "../../Store/AllProjectsSlice";
import IfProjectCreated from "./IfProjectCreated";
import applogo from "../../Assets/applogo.png";
import _ from "lodash";
import { getUsers } from "../../Store/UserSlice";
import { handleRequest } from "../../api/apiCall";
import { EDIT_APP } from "../../util/ApiEndPoints";
import { createAppfun, getAppList } from "../../Store/AppListSlice";
import MainDashboardNavbar from "./MainDashboardNavbar";
import Sidebar from "../Sidebar";

const MainDashboardMainUserPage = () => {
  const userConditions = useSelector((state) => state.userConditions);
  const { appList } = useSelector((state) => state?.appList);
  const { usersList } = useSelector((state) => state?.usersList);
  const [renderUsers, setRenderUsers] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeApp, setActiveApp] = useState(null);
  const savedUserDetails = localStorage.getItem("userDetails");
  const userDetails = JSON.parse(savedUserDetails);
  // console.log("console.log==activeapp", activeApp);
  useEffect(() => {
    const payload = { orgId: userDetails?.orgId };
    dispatch(getAppList({ payload, dispatch }));
  }, [dispatch, userDetails?.orgId]);
  useEffect(() => {
    if (appList) {
      setActiveApp(appList[0]);
    }
  }, [appList]);

  useEffect(() => {
    setRenderUsers(usersList?.users);
  }, [usersList]);
  const handleAppClick = (app) => {
    console.log("App||", app);
    setActiveApp(app);
    dispatch(
      createAppfun({
        appId: app?.app_id,
        orgId: userDetails?.orgId,
        baseAppId: "",
      })
    );
    // if (userConditions.isuserAccess) {
    dispatch(
      getUsers({
        orgId: userDetails?.orgId,
        appId: app?.app_id,
      })
    );
    // } else {
    dispatch(
      getAllBaseProjects({
        orgId: userDetails?.orgId,
        appId: app?.app_id,
      })
    );
    // }
  };

  const updateUserDetail = (val, key, id) => {
    const newUsers = _.map(renderUsers, (usr) => {
      if (usr.emailId === id) {
        return { ...usr, [key]: val };
      } else {
        return usr;
      }
    });

    return newUsers;
  };

  const onSelectValue = (val, key, id) => {
    const usrs = updateUserDetail(val, key, id);
    const savedUserDetails = localStorage.getItem("userDetails");
    const userDetails = JSON.parse(savedUserDetails);

    const payload = {
      appId: activeApp?.app_id,
      orgId: userDetails?.orgId,
      users: usrs,
    };
    const customHeaders = {
      Authorization: `Bearer ${userDetails.access_token}`,
      // Add more custom headers if needed
    };
    handleRequest(EDIT_APP, "POST", payload, customHeaders)
      .then((responseData) => {
        setRenderUsers(usrs);
        alert(responseData.message);
      })
      .catch((error) => {
        console.error("EDIT_APP error:", error);
      });
  };

  if (userConditions.iscreateProject) {
    // console.log(userConditions);
    return <IfProjectCreated activeApp={activeApp} />;
  }
  return (
    <div className="maindashboard">
      <Sidebar />
      <div className="maindashboardmaincontent">
        <MainDashboardNavbar />
        <div className="maindashboardmainbody">
          <div className="refreshoraccessuser">
            <button
              onClick={async () => {
                window.location.reload();
                // setTimeout(() => {
                //   alert("hii");
                // }, 4000);
                // dispatch(changeUserAccess(true));
              }}
            >
              Refresh
            </button>
            <button
              onClick={() =>
                navigate("/user/userdetails", { state: { key: activeApp } })
              }
            >
              Add User
            </button>
          </div>

          <div className="maindashboardmainbodyheader">
            <div className="appandcreateuers">
              <h2>Apps</h2>
            </div>
            <div className="maindashboardmainbodyheaderapplist">
              {appList?.map((app, index) => (
                <div key={index} className="Appslist">
                  <div
                    onClick={() => handleAppClick(app)}
                    className={`firstapp ${
                      activeApp?.app_id === app?.app_id ? "activeapp" : ""
                    }`}
                  >
                    <img src={applogo} alt="applogo" className="applistlogo" />
                  </div>
                  <h4>{app.appName}</h4>
                </div>
              ))}
            </div>
          </div>

          <div className="maindashboardmainbodyfooter">
            <h2>Users</h2>
            <div className="adminuserslist">
              <div className="maindashboardprojectdetail">
                <div>Email ID</div>
                <div>FIRST NAME</div>
                <div>LAST NAME</div>
                <div>ROLE</div>
                <div>Weightage</div>
              </div>
              <div className="maindashboardprojectdetailcontent">
                {renderUsers?.map((user, index) => (
                  <div key={index} className="maindashboardprojectdetail">
                    <p>{user.emailId}</p>
                    <p>{user.userFirstName}</p>
                    <p>{user.userLastName}</p>
                    <div>
                      {/* {console.log(user.role, user.weightage)} */}
                      <div className="rolewithdropdown">
                        {/* {console.log("userrole>>", user.role)} */}

                        <select
                          value={user.role}
                          onChange={(e) =>
                            onSelectValue(e.target.value, "role", user.emailId)
                          }
                          className="selecteditem"
                        >
                          <option>Null</option>
                          <option>Read</option>
                          <option>Write</option>
                          <option>Admin</option>
                        </select>
                      </div>
                    </div>
                    <div>
                      <div>
                        <select
                          value={user.weightage}
                          onChange={(e) =>
                            onSelectValue(
                              e.target.value,
                              "weightage",
                              user.emailId
                            )
                          }
                          className="selecteditem"
                        >
                          <option>Beginner</option>
                          <option>Intermediatery</option>
                          <option>Advanced</option>
                        </select>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainDashboardMainUserPage;
