import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  ADD_PROJECT,
  ADD_TRACES,
  EDIT_PROJECT,
  GET_BASE_PROJECTS,
  GET_COMBINEFEEDBACK,
  GET_PROJECT,
  GET_PROJECTDETAILS,
  GET_PROJECTVERISON,
} from "../util/ApiEndPoints";

export const getAllProjects = createAsyncThunk(
  "getAllProjects",
  async (payload) => {
    const savedUserDetails = localStorage.getItem("userDetails");
    const userDetails = JSON.parse(savedUserDetails);
    const customHeaders = {
      Authorization: `Bearer ${userDetails?.access_token}`,
    };
    // console.log("Payload>>", payload);
    try {
      const res = await axios.post(GET_PROJECT, payload, {
        headers: customHeaders,
      });
      return res.data;
    } catch (error) {
      console.log("Error", error);
    }
  }
);
export const getAllBaseProjects = createAsyncThunk(
  "getAllBaseProjects",
  async (payload) => {
    const savedUserDetails = localStorage.getItem("userDetails");
    const userDetails = JSON.parse(savedUserDetails);
    const customHeaders = {
      Authorization: `Bearer ${userDetails?.access_token}`,
    };
    // console.log("Payload>>>>>>>>", payload);
    try {
      const res = await axios.post(GET_BASE_PROJECTS, payload, {
        headers: customHeaders,
      });
      return res.data;
    } catch (error) {
      console.log("Error", error);
    }
  }
);

export const addProject = createAsyncThunk(
  "addProject",
  async ({ payload, url, dispatch, activeApp, orgId }) => {
    const savedUserDetails = localStorage.getItem("userDetails");
    const userDetails = JSON.parse(savedUserDetails);
    const customHeaders = {
      Authorization: `Bearer ${userDetails?.access_token}`,
    };
    // console.log("Payload::url", payload, url);
    try {
      const res = await axios.post(url, payload, {
        headers: customHeaders,
      });
      if (res.status === 200) {
        dispatch(
          getAllBaseProjects({ orgId: orgId, appId: activeApp?.app_id })
        );
      }
      return res.status;
    } catch (error) {
      console.log("Error", error);
    }
  }
);
export const editProject = createAsyncThunk("editProject", async (payload) => {
  const savedUserDetails = localStorage.getItem("userDetails");
  const userDetails = JSON.parse(savedUserDetails);
  const customHeaders = {
    Authorization: `Bearer ${userDetails?.access_token}`,
  };
  // console.log("Payload::url", payload, url);
  try {
    const res = await axios.post(EDIT_PROJECT, payload, {
      headers: customHeaders,
    });
    if (res.status === 200) {
      // alert("Project Edited");
      alert("Training Wizard Started");
    }
    return res.status;
  } catch (error) {
    console.log("Error", error);
  }
});
export const startTrainingProject = createAsyncThunk(
  "startTrainingProject",
  async ({
    payload,
    dispatch,
    tempUpdatedUserslist,
    activeProject,
    collectedMergeTraces,
  }) => {
    const savedUserDetails = localStorage.getItem("userDetails");
    const userDetails = JSON.parse(savedUserDetails);
    const customHeaders = {
      Authorization: `Bearer ${userDetails?.access_token}`,
    };
    // console.log("Payload::url", payload, url);
    try {
      const res = await axios.post(ADD_PROJECT, payload, {
        headers: customHeaders,
      });
      if (res.status === 200) {
        let payload = {
          projectId: res.data.projectId,
          projectName: res.data.projectName,
          rlhfObjective: activeProject?.rlhfObjective,
          users: tempUpdatedUserslist,
        };
        dispatch(getProjectVersion({ projectId: activeProject?.projectID }));
        dispatch(editProject(payload));
        const response = await axios.post(
          ADD_TRACES,
          [
            {
              projectId: res.data.projectId,
              traceId: [...activeProject.traceId, ...collectedMergeTraces],
            },
          ],
          {
            headers: customHeaders,
          }
        );
        if (response.status === 200) {
          alert(response.data.message);
        }
        try {
          const savedUserDetails = localStorage.getItem("userDetails");
          const userDetails = JSON.parse(savedUserDetails);
          const resp = axios.post(GET_COMBINEFEEDBACK, {
            projectId: res.data.projectId,
            orgId: userDetails?.orgId,
            userId: userDetails?.emailId,
          });
          console.log("GET_COMBINEFEEDBACK", resp.data);
        } catch (error) {
          console.log("Error", error);
        }
        return res.data;
      }
      return res.status;
    } catch (error) {
      console.log("Error", error);
    }
  }
);
export const getProjectDetail = createAsyncThunk(
  "getProjectDetail",
  async (payload) => {
    const savedUserDetails = localStorage.getItem("userDetails");
    const userDetails = JSON.parse(savedUserDetails);
    const customHeaders = {
      Authorization: `Bearer ${userDetails?.access_token}`,
    };
    // console.log("projectdatapayload", payload);
    try {
      const res = await axios.post(GET_PROJECTDETAILS, payload, {
        headers: customHeaders,
      });
      if (res.status === 200) {
        // console.log("projectdetail||||||", res);
        return res.data;
      }
    } catch (error) {
      console.log("Error", error.message);
    }
  }
);
export const getProjectVersion = createAsyncThunk(
  "getProjectVersion",
  async (payload) => {
    const savedUserDetails = localStorage.getItem("userDetails");
    const userDetails = JSON.parse(savedUserDetails);
    const customHeaders = {
      Authorization: `Bearer ${userDetails?.access_token}`,
    };
    // console.log("projectVersionpayload??????", payload);
    try {
      const res = await axios.post(GET_PROJECTVERISON, payload, {
        headers: customHeaders,
      });
      // if (res.status === 200) {
      // console.log("projectversion:::::::", res, res.status, res.data);
      return res.data;
      // }

      // return res.data;
    } catch (error) {
      console.log("Error", error.message);
    }
  }
);

const allProjects = createSlice({
  name: "allProjects",
  initialState: {
    projectsList: [],
    projectDetailData: {},
    projectVersions: [],
    baseProjectsList: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllProjects.pending, (state) => {
        state.status = "Pending";
      })
      .addCase(getAllProjects.fulfilled, (state, { payload }) => {
        state.status = "Success";
        state.projectsList = payload;
      })
      .addCase(getAllProjects.rejected, (state, action) => {
        state.status = "Failed";
        state.error = action.error.message;
      })
      .addCase(addProject.pending, (state) => {
        state.status = "Pending";
      })
      .addCase(addProject.fulfilled, (state) => {
        state.status = "Success";
      })
      .addCase(addProject.rejected, (state, action) => {
        state.status = action.error.message;
      })
      .addCase(getProjectDetail.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getProjectDetail.fulfilled, (state, { payload }) => {
        // console.log("projectdetaildata????????", payload);
        state.status = "successed";
        state.projectDetailData = payload;
      })
      .addCase(getProjectDetail.rejected, (state, action) => {
        state.status = "Failed";
        state.error = action.error.message;
      })
      .addCase(getProjectVersion.pending, (state) => {
        state.status = "Pending";
      })
      .addCase(getProjectVersion.fulfilled, (state, { payload }) => {
        // console.log("projecversiondata||||||", payload);
        state.status = "Sussecced";
        state.projectVersions = payload?.otherVersions;
      })
      .addCase(getProjectVersion.rejected, (state, action) => {
        state.status = "Failed";
        state.error = action.error.message;
      })
      .addCase(getAllBaseProjects.pending, (state) => {
        state.status = "Pending";
      })
      .addCase(getAllBaseProjects.fulfilled, (state, { payload }) => {
        state.status = "successed";
        state.baseProjectsList = payload;
      })
      .addCase(getAllBaseProjects.rejected, (state, action) => {
        state.status = "Failed";
        state.error = action.error.message;
      })
      .addCase(startTrainingProject.pending, (state) => {
        state.status = "Pending";
      })
      .addCase(startTrainingProject.fulfilled, (state) => {
        state.status = "successed";
      })
      .addCase(startTrainingProject.rejected, (state, action) => {
        state.status = "Failed";
        state.error = action.error.message;
      })
      .addCase(editProject.pending, (state) => {
        state.status = "Pending";
      })
      .addCase(editProject.fulfilled, (state, { payload }) => {
        state.status = "Successed";
      })
      .addCase(editProject.rejected, (state, action) => {
        state.status = "Failed";
        state.error = action.error.message;
      });
  },
});

export default allProjects.reducer;
