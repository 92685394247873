import * as React from "react";
import Modal from "@mui/joy/Modal";
import Sheet from "@mui/joy/Sheet";
import "./ReportModal.css";
// import { useState } from "react";
// import { useEffect } from "react";

// import { ADD_PROJECT, EDIT_PROJECT } from "../../../util/ApiEndPoints";
export default function DeleteProjectModal({ show, setshow, allReportsData }) {
  //   const [showweightconfirm, setshowweightconfirm] = useState(false);

  // console.log("updatedUsersList>>", updatedUsersList);
  //   console.log('show',show)
  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={show}
      onClose={() => setshow(false)}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Sheet
        variant="outlined"
        sx={{
          width: "80%",
          height: "70%",
          display: "flex",
          flexDirection: "column",
          borderRadius: "md",
          pb: 0,
          boxShadow: "sm",
          background: "white",
        }}
      >
        <div className="reportmodal">
          <div className="reportmodalheader">
            <h2>A/B Testing Reports</h2>
            <button onClick={() => setshow(false)}>X</button>
          </div>
          <div className="reportmodalbody">
            <div className="reportmodalbodytop">
              <h3>Created By</h3>
              <h3>Creation Date</h3>
              <h3>Project Version 1</h3>
              <h3>Project Version 2</h3>
              <h3>Link to Report</h3>
            </div>
            <div className="reportmodalbodycenter">
              {allReportsData &&
                allReportsData?.map((report) => (
                  <div className="reportmodalbodycentercontent">
                    <h4></h4>
                    <p>{report?.creation_date}</p>
                    <h4></h4>
                    <h4></h4>
                    <a
                      className="abreporturl"
                      href={report.presigned_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {report.key}
                    </a>
                  </div>
                ))}
            </div>
            <div className="reportmodalbodyfooter"></div>
          </div>
        </div>
      </Sheet>
    </Modal>
  );
}
