import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getTraces = createAsyncThunk("getTraces", async (url) => {
  // console.log("uslllllll", url);
  try {
    const response = await axios.get(url);

    // console.log("response||>",response.data);
    return response.data;
  } catch (error) {
    console.error("Error", error);
    throw error;
  }
});

const TracesSlice = createSlice({
  name: "traces",
  initialState: {
    traces: null,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTraces.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTraces.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.traces = action.payload;
      })
      .addCase(getTraces.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default TracesSlice.reducer;
