import React, { useState } from "react";
import "./LeftCardProperty.css";
// import _ from "lodash";
import { FaLessThan } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const LeftCardProperty = ({
  state,
  clickedTrace,
  setSingleTraceClicked,
  onSelectSubTrace,
  patentTrace,
}) => {
  // console.log("state>>", state);
  // const lastTrace = useMemo(() => {
  //   const answerItem = state?.filter((item) => !_.isEmpty(item.output));
  //   return answerItem[0];
  // }, [state]);
  console.log("clickedTrace", clickedTrace);
  const [bottomTraceClicked, setBottomTraceClicked] = useState(false);
  const navigate = useNavigate();
  // console.log("lastTrace", lastTrace);
  const handletraceinput = (input) => {
    // console.log("type", typeof item);
    // console.log("inputtt", input);
    if (typeof input === "string") {
      return input;
    } else if (input === null) {
      return "";
    } else if (input[0]) {
      return input[0]?.text;
    }
  };
  const handletraceinputbottom = (item) => {
    console.log("inputttt", item, "id", item, "type", typeof item);
    if (typeof item === "string") {
      return item;
    } else if (item === null) {
      return "";
    } else if (item) {
      return item[0]?.text;
    }
  };
  return (
    <div className="leftCardproperty">
      <div className="cardNavbar">
        <div className="cardbacktohome" onClick={() => navigate("/user/home")}>
          <button>
            <FaLessThan />
            Back to Trace List
          </button>
        </div>
        <div className="cardID">
          <p>{patentTrace?.id}</p>
        </div>
      </div>
      <div className="cardBody">
        <div className="cardquestion">
          <p>{patentTrace?.input}</p>
        </div>
        <div className="cardBodycontent">
          {state
            .slice(0, -1)
            // .filter((item) => _.isEmpty(item.output))
            .map((item) => (
              <div
                key={item?.id}
                className={`cardBodycontentDetail ${
                  item.isSelected && "subtraceSelected"
                }`}
                onClick={() => {
                  onSelectSubTrace(item);
                  setBottomTraceClicked(false);
                }}
              >
                {/* {console.log("selected", item.isSelected, item)} */}
                <div className="cardBodycontentDetailfirstchild">
                  <div>
                    <h4>#{item?.id}</h4>
                  </div>
                  <div>
                    <p className="subtraceinput">
                      {handletraceinput(item?.input)}
                    </p>
                  </div>
                  <div>
                    <p className="subtraceinput">{item.name}</p>
                  </div>
                </div>
                <div className="cardBodycontentDetailsecondchild">
                  <div className="lcr">{item.byFunc}</div>
                  <div>{item.date}</div>
                </div>
              </div>
            ))}
          <div
            className={`cardBodycontentDetail ${
              bottomTraceClicked && "subtraceSelected"
            }`}
            onClick={() => {
              onSelectSubTrace(
                state[state.length - 1] || state[state.length - 2]
              );
              setBottomTraceClicked(true);
            }}
          >
            {/* {console.log("selected", item.isSelected, item)} */}
            <div className="cardBodycontentDetailfirstchild">
              <div>
                <h4>
                  #{state[state.length - 1]?.id || state[state.length - 2]?.id}
                </h4>
              </div>
              <div>
                <p className="subtraceinput">
                  {handletraceinput(
                    state[state.length - 1]?.output ||
                      state[state.length - 2]?.output ||
                      "answer not found"
                  )}
                </p>
              </div>
              <div>
                <p className="subtraceinput">
                  {state[state.length - 1]?.name ||
                    state[state.length - 2]?.name}
                </p>
              </div>
            </div>
            <div className="cardBodycontentDetailsecondchild">
              <div className="lcr">
                {state[state.length - 1]?.byFunc ||
                  state[state.length - 2]?.byFunc}
              </div>
              <div>
                {state[state.length - 1]?.date || state[state.length - 2]?.date}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cardFooter">
        <div className="leftcardFooter"></div>
        <div className="rightcardFooter">
          <div className="lowerrightcardFooter">
            {/* <div className="cardBodycontentDetailfirstchild">
                <div
                  style={{
                    display: "flex",
                    marginRight: "auto",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <p className="cardvalue">
                    #
                    {state[state.length - 1]?.id || state[state.length - 2]?.id}
                  </p>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <p className="subtraceinput">
                      {state[state.length - 1]?.input ||
                        state[state.length - 2]?.input}
                    </p>
                    <p>
                      {state[state.length - 1]?.name ||
                        state[state.length - 2]?.name}
                    </p>
                  </div>
                </div>
                <p>
                  {state[state.length - 1]?.date ||
                    state[state.length - 2]?.date}
                </p>
              </div> */}
            <div
              style={{
                display: "flex",
                flexDirection: "column", // Ensure vertical stacking of content
                gap: "10px",
                marginTop: "10px",
                alignItems: "flex-start", // Align content to the start
                height: "150px", // Fixed smaller height for the card
                overflowY: "auto", // Enable vertical scrolling if content overflows
                overflowX: "hidden", // Prevent horizontal scrolling
              }}
            >
              {/* <h3>Answer:</h3>
                {state[state.length - 1]?.output ||
                  state[state.length - 2]?.output ||
                  "answer not found"} */}
              <h3>
                {handletraceinputbottom(
                  clickedTrace?.input && clickedTrace?.output
                    ? clickedTrace.output
                    : clickedTrace?.input
                )}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftCardProperty;
